import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Form, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import useForm from '@app/hooks/useForm';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import {
  AchAccount,
  achAccountFromBoolean,
  booleanOrNullFromAchAccount,
  createSupplierValidationRecordAccountVerificationAllUpdateFieldsFromRecord,
  EvidenceType,
  StoreSupplierValidationAccountVerificationRecordRequest,
  stringOrNullForLocalFormat,
  SupplierValidationAccountVerificationRecord,
  SupplierValidationAccountVerificationRecordServerResponse,
  SupplierValidationRecord,
  supplierValidationRecordAccountVerificationAllUpdateFieldsEquators,
  SupplierValidationRecordStatus,
  SupplierValidationVerificationRecordEvidence,
  transformSupplierValidationAccountVerification,
} from '@mortee/domain/validationSystem';
import { FormFieldDecorators } from '@app/utils/form/form';
import { SUPPLIER_VALIDATION_ACCOUNT_VERIFICATION_DATA_FIELD_MAX_LENGTH } from '@mortee/domain/validationSystemFields';
import {
  createEqualityCheckFunctionCaseInsensitiveIgnoredChars,
  removeCharsAndUpperCase,
  trim,
  trimToNull,
} from '@app/utils/stringUtils';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import Collapse, { CollapsePanelStickyHeader } from '@app/components/Collapse';
import useFormChangesStatus from '@app/hooks/useFormChangesStatus';
import VerificationFormCollapseHeader from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/VerificationFormCollapseHeader';
import Button from '@app/components/Button';
import SVG from '@app/components/SVG';
import TrashIcon from '@app/images/trash-black.svg';
import { itemOrFirst } from '@app/utils/arrayUtils';
import {
  convertValidatedAccountValidationSupplierValidationLevelToText,
  ValidatedPayeeAccountValidationStatusSupplierValidationVerification,
} from '@app/domain/validatedPayeeAccount';
import { notFutureTimestampValidator } from '@app/utils/validators';
import CountryDropdown from '@mortee/routes/validatedPayeesManagement/CountryDropdown';
import NakedCalendarDatePicker from '@app/components/inputs/NakedCalendarDatePicker';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import DropdownItem from '@app/components/inputs/DropdownItem';
import { ACCOUNT_FIELD_MAX_LENGTH, VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { isSwiftCodeValid, swiftCodeWithSuffixValidator, validateIban } from '@app/domain/accountDetailsValidators';
import { H6StartTransparentBlack800 } from '@app/components/Text';
import validationSystemServices from '@mortee/services/validationSystemServices';
import { COUNTRY_CODES, getCountryName } from '@app/domain/countries';
import FormItemBoxWithSuggestions from '@app/components/inputs/FormItemBoxWithSuggestions';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import { confirmAsync, ModalBody, ModalTextLine, ModalTitle, WrappingModalContainer } from '@app/components/Modal';
import CopyIcon from '@app/images/copy.svg';
import FormRadioGroup from '@app/components/inputs/FormRadioGroup';
import { isTruthy, SWIFT_EQUALS_FUNCTION } from '@app/utils/utils';
import useLoadable from '@app/hooks/loadable/useLoadable';
import useBankNameByCountryCodeAndBankCode from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/hooks/useBankNameByCountryCodeAndBankCode';
import { Suggestion } from '@app/components/ValueSuggestions';

interface CreateVerificationDataRecordAccountFormFields {
  countryCode: string | undefined;
  swift: string | undefined;
  bankCode: string | undefined;
  bankName: string | undefined;
  branchCode: string | undefined;
  accountNumber: string | undefined;
  iban: string | undefined;
  furtherCredit: string | undefined;
  effectiveDate: number | undefined;
  type: ValidatedPayeeAccountValidationStatusSupplierValidationVerification | undefined;
  achDidReceiveAccount: AchAccount;
  achDidReceiveEin: AchAccount;
  localFormat: string | undefined;
}

interface Props extends FormComponentProps<CreateVerificationDataRecordAccountFormFields> {
  accountStaticId: string | null;
  displayIndex: number;
  verificationRecordAccountData: SupplierValidationAccountVerificationRecord | null;
  className?: string;
  evidenceData: SupplierValidationVerificationRecordEvidence | null | undefined;
  supplierValidationRecord: SupplierValidationRecord;
  onSaved(accountData: SupplierValidationAccountVerificationRecord): void;
  onDeleted(): Promise<void>;
  onIsFormUnsavedChanged(isFormUnsaved: boolean): void;
  onDuplicate(): void;
}

const VerificationRecordAccountForm: FC<Props> = observer((props) => {
  const {
    displayIndex,
    verificationRecordAccountData,
    onSaved,
    onDeleted,
    onIsFormUnsavedChanged,
    accountStaticId,
    evidenceData,
    supplierValidationRecord,
    className,
    onDuplicate,
  } = props;

  const { permissionsStore } = useInfraStores<MorteeMode>();

  const { form, showFormErrors, validateFields, setShowFormErrors } = useForm(props);

  const [isSaving, setIsSaving] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const isNewEntity = !accountStaticId;

  const bankNameSuggestionBankCodeAndCountryCodeLoadable = useBankNameByCountryCodeAndBankCode(
    form.getFieldValue('countryCode'),
    form.getFieldValue('bankCode'),
  );

  const [bankNameSuggestionSwiftLoadable] = useLoadable(async () => {
    const swift = form.getFieldValue('swift');

    if (!isSwiftCodeValid(swift)) {
      return null;
    }

    return await validationSystemServices.getBankNameBySwiftCode(swift.toUpperCase());
  }, [form.getFieldValue('swift')]);

  const isPendingApprovalAndDoesntHaveApprovalRole =
    !permissionsStore.isValidationApprover &&
    supplierValidationRecord.status.value === SupplierValidationRecordStatus.waitingForApproval;

  const verificationRecordAccountBaseData = useMemo((): StoreSupplierValidationAccountVerificationRecordRequest => {
    if (!verificationRecordAccountData || !accountStaticId) {
      return {
        countryCode: null,
        swift: null,
        bankCode: null,
        bankName: null,
        branchCode: null,
        accountNumber: null,
        iban: null,
        furtherCredit: null,
        validationTimestamp: null,
        type: null,
        achDidReceiveAccount: null,
        achDidReceiveEin: null,
        localFormat: null,
      };
    }

    return createSupplierValidationRecordAccountVerificationAllUpdateFieldsFromRecord(verificationRecordAccountData);
  }, [verificationRecordAccountData, accountStaticId]);

  const { isFormNotInitialized, amountOfChanges, amountOfErrors, storeRequest, updateRequest } = useFormChangesStatus(
    verificationRecordAccountBaseData,
    form,
    createUpdateRequestAllUpdateFieldsFromFormFields,
    supplierValidationRecordAccountVerificationAllUpdateFieldsEquators,
  );

  const hasChanges = !!amountOfChanges;

  useEffect(() => {
    onIsFormUnsavedChanged(hasChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- onIsFormUnsavedChanged can change anytime
  }, [hasChanges]);

  const handleSave = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    const formFields = await validateFields();
    formFields && createOrUpdateVerificationAccount();
  };

  const createOrUpdateVerificationAccount = async (): Promise<void> => {
    if (isFormNotInitialized) {
      return;
    }

    setIsSaving(true);

    try {
      let accountVerificationRecordServerResponse: SupplierValidationAccountVerificationRecordServerResponse;

      if (isNewEntity) {
        accountVerificationRecordServerResponse = await validationSystemServices.storeSupplierValidationAccountVerificationRecord(
          supplierValidationRecord.staticId,
          storeRequest,
        );
      } else {
        accountVerificationRecordServerResponse = await validationSystemServices.updateSupplierValidationAccountVerificationRecord(
          supplierValidationRecord.staticId,
          accountStaticId,
          updateRequest,
        );
      }

      setIsOpen(false);
      onSaved(transformSupplierValidationAccountVerification(accountVerificationRecordServerResponse));
    } finally {
      setIsSaving(false);
    }
  };

  async function handleDelete(e: React.MouseEvent<HTMLButtonElement>): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    if (!(await shouldDeleteAccountConfirmation())) {
      return;
    }

    setIsSaving(true);

    try {
      if (!isNewEntity) {
        await validationSystemServices.deleteSupplierValidationAccountVerificationRecord(
          supplierValidationRecord.staticId,
          accountStaticId,
        );
      }
      await onDeleted();
    } finally {
      setIsSaving(false);
    }
  }

  function handleDuplicate(e: React.MouseEvent<HTMLButtonElement>): void {
    e.preventDefault();
    e.stopPropagation();

    onDuplicate();
  }

  async function shouldDeleteAccountConfirmation(): Promise<boolean> {
    if (isNewEntity && !hasChanges) {
      return true;
    }

    const renderConfirmationModal = (): React.ReactElement => {
      return (
        <WrappingModalContainer>
          <ModalTitle>Delete Account</ModalTitle>
          <ModalBody>
            <ModalTextLine>You are about to delete this account. Are you sure you want to proceed?</ModalTextLine>
          </ModalBody>
        </WrappingModalContainer>
      );
    };

    return await confirmAsync({
      content: renderConfirmationModal(),
      okText: 'YES',
      cancelText: 'NO',
      icon: <span />,
      maskClosable: false,
      width: 550,
    });
  }

  function getBankNameSuggestions(): Suggestion<string | null | undefined>[] {
    return [
      {
        name: 'Evidence',
        value: evidenceData?.data.bankName?.toUpperCase(),
        displayValue: evidenceData?.data.bankName,
      },
      ...getBankNameDatabaseSuggestions(),
    ];
  }

  function getBankNameDatabaseSuggestions(): Suggestion<string | null | undefined>[] {
    if (bankNameSuggestionSwiftLoadable.result === bankNameSuggestionBankCodeAndCountryCodeLoadable.result) {
      return [
        {
          name: 'Database (all)',
          value: bankNameSuggestionBankCodeAndCountryCodeLoadable.result,
          displayValue: bankNameSuggestionBankCodeAndCountryCodeLoadable.result,
        },
      ];
    }

    return [
      {
        name: 'Database (swift)',
        value: bankNameSuggestionSwiftLoadable.result,
        displayValue: bankNameSuggestionSwiftLoadable.result,
      },
      {
        name: 'Database (bank code)',
        value: bankNameSuggestionBankCodeAndCountryCodeLoadable.result,
        displayValue: bankNameSuggestionBankCodeAndCountryCodeLoadable.result,
      },
    ];
  }

  const renderValidationTypeDropdown = (name: string, placeholder: string): ReactElement => {
    return (
      <NakedDropdown accessibilityLabel='Account Validation Type' name={name} dataTestId={name} placeholder={placeholder}>
        {[
          ValidatedPayeeAccountValidationStatusSupplierValidationVerification.arDirect,
          ValidatedPayeeAccountValidationStatusSupplierValidationVerification.arLinked,
          ValidatedPayeeAccountValidationStatusSupplierValidationVerification.alreadyValidated,
          ValidatedPayeeAccountValidationStatusSupplierValidationVerification.bankValidation,
          ValidatedPayeeAccountValidationStatusSupplierValidationVerification.direct,
          ValidatedPayeeAccountValidationStatusSupplierValidationVerification.internalValidation,
          ValidatedPayeeAccountValidationStatusSupplierValidationVerification.light,
          ValidatedPayeeAccountValidationStatusSupplierValidationVerification.linked,
          ValidatedPayeeAccountValidationStatusSupplierValidationVerification.lyonsValidation,
        ].map((validationType) => {
          const validationTypeText = convertValidatedAccountValidationSupplierValidationLevelToText(validationType);
          return (
            <ValidationTypeDropdownIItem key={validationType} value={validationType} textWhenSelected={validationTypeText}>
              <div>{validationTypeText}</div>
            </ValidationTypeDropdownIItem>
          );
        })}
      </NakedDropdown>
    );
  };

  const header = (
    <StickyHeaderContainer>
      <VerificationFormCollapseHeader
        dataTestId='verification-record-account-details-expander'
        title={`ACCOUNT NO. ${displayIndex}`}
        isNewEntity={isNewEntity}
        amountOfChanges={amountOfChanges}
        amountOfErrors={amountOfErrors}
        isLoading={isSaving}
        actionsVisibility={isOpen ? 'visible' : 'hover'}
      />
      <ActionsContainer>
        <Button
          id='btn-verification-data-account-save'
          disabled={!hasChanges || isSaving || isFormNotInitialized}
          onClick={handleSave}
          disableAnimations
        >
          SAVE
        </Button>
        {!isPendingApprovalAndDoesntHaveApprovalRole && (
          <>
            <Tooltip
              title={
                <div data-testid='verification-data-account-duplicate-tooltip-text'>{`Duplicate account number ${displayIndex} saved data`}</div>
              }
            >
              <HeaderButton
                id='verification-data-account-duplicate'
                appearance='full'
                colorScheme='white'
                onClick={handleDuplicate}
                disabled={isNewEntity}
              >
                <SVG
                  accessibilityLabel={`Duplicate account ${displayIndex} saved data`}
                  width={14}
                  height={14}
                  image={CopyIcon}
                />
              </HeaderButton>
            </Tooltip>
            <Tooltip
              title={<div data-testid='verification-data-account-delete-tooltip-text'>{`Delete Account ${displayIndex}`}</div>}
            >
              <HeaderButton id='verification-data-account-delete' appearance='full' colorScheme='white' onClick={handleDelete}>
                <SVG accessibilityLabel={`Delete account ${displayIndex}`} image={TrashIcon} width={14} height={14} />
              </HeaderButton>
            </Tooltip>
          </>
        )}
      </ActionsContainer>
    </StickyHeaderContainer>
  );

  function renderFormFields(): ReactElement {
    const fieldDecorators = createFieldDecorators(verificationRecordAccountData);
    const isAccountCountryUS = form.getFieldValue('countryCode') === COUNTRY_CODES.UnitedStates;
    const doesCountryHaveLocalFormat = form.getFieldValue('countryCode') === COUNTRY_CODES.Mexico;

    const upperCaseFieldValue = (e: React.FocusEvent<HTMLInputElement>, fieldName: string): void => {
      form.setFieldsValue({
        [fieldName]: e.target.value.toUpperCase(),
      });

      validateFields();
    };

    const charsToIgnore = ['-', '/', ' ', '.', '\\'];

    return (
      <Collapse
        activeKey={isOpen ? 'account' : undefined}
        onChange={(keyOrKeys): void => setIsOpen(itemOrFirst(keyOrKeys) === 'account')}
      >
        <CollapsePanelStickyHeader key='account' forceRender header={header}>
          <NsknoxForm
            form={form}
            showErrors={showFormErrors}
            disabled={isSaving || isPendingApprovalAndDoesntHaveApprovalRole}
            className={className}
            setShowErrors={setShowFormErrors}
          >
            <div id='verification-data-account'>
              <FieldsTitle>Validation</FieldsTitle>
              <InputGrid>
                <FormItemBox fieldName='type' fieldDecoratorOptions={fieldDecorators.type}>
                  {renderValidationTypeDropdown('drp-verification-data-account-type', 'Validation Type')}
                </FormItemBox>
                <FormItemBox fieldName='effectiveDate' fieldDecoratorOptions={fieldDecorators.effectiveDate}>
                  <NakedCalendarDatePicker
                    placeholder='Validation Date'
                    id='date-picker-verification-data-account-validation-date-timestamp'
                    accessibilityLabel='Validation Date'
                    disabledDate={(date): boolean => date?.isAfter() ?? false}
                  />
                </FormItemBox>
              </InputGrid>
              <FieldsTitle>Account</FieldsTitle>
              <InputGrid>
                <FormItemBoxWithSuggestions
                  dataTestId='inpt-verification-data-account-country-suggestion'
                  fieldName='countryCode'
                  fieldDecoratorOptions={fieldDecorators.countryCode}
                  suggestions={[
                    {
                      name: 'Registration-Form',
                      value: supplierValidationRecord.supplierRegistrationProcess?.accountBankCountryCode,
                      displayValue: getCountryName(supplierValidationRecord.supplierRegistrationProcess?.accountBankCountryCode),
                    },
                  ]}
                >
                  <CountryDropdown
                    onChange={(newValue): void => {
                      form.setFieldsValue({
                        achDidReceiveEin:
                          newValue === COUNTRY_CODES.UnitedStates
                            ? form.getFieldValue('achDidReceiveEin')
                            : AchAccount.irrelevant,
                        achDidReceiveAccount:
                          newValue === COUNTRY_CODES.UnitedStates
                            ? form.getFieldValue('achDidReceiveAccount')
                            : AchAccount.irrelevant,
                        localFormat: newValue === COUNTRY_CODES.Mexico ? form.getFieldValue('localFormat') : undefined,
                      });
                    }}
                    accessibilityLabel='Country code dropdown'
                    name='drp-verification-data-account-countryCode'
                    dataTestId='drp-verification-data-account-countryCode'
                  />
                </FormItemBoxWithSuggestions>
                <FormItemBoxWithSuggestions<string>
                  dataTestId='inpt-verification-data-account-bank-name-suggestion'
                  fieldName='bankName'
                  fieldDecoratorOptions={fieldDecorators.bankName}
                  equalityCheck={createEqualityCheckFunctionCaseInsensitiveIgnoredChars(['.', ',', '(', ')', '-', '/'])}
                  suggestions={getBankNameSuggestions()}
                >
                  <NakedFormInput
                    onBlur={(e): void => upperCaseFieldValue(e, 'bankName')}
                    name='inpt-verification-data-account-bank-name'
                    dataTestId='inpt-verification-data-account-bank-name'
                    type='text'
                    placeholder='Bank Name'
                  />
                </FormItemBoxWithSuggestions>
                <FormItemBoxWithSuggestions
                  dataTestId='inpt-verification-data-account-bank-code-suggestion'
                  fieldName='bankCode'
                  fieldDecoratorOptions={fieldDecorators.bankCode}
                  equalityCheck={createEqualityCheckFunctionCaseInsensitiveIgnoredChars(charsToIgnore)}
                  suggestions={[
                    supplierValidationRecord.supplierRegistrationProcess?.abaRouting && {
                      name: 'Registration-Form (ACH)',
                      value: removeCharsAndUpperCase(
                        supplierValidationRecord.supplierRegistrationProcess.accountDetails.bankCode,
                        charsToIgnore,
                      ),
                      displayValue: supplierValidationRecord.supplierRegistrationProcess.accountDetails.bankCode,
                    },
                    supplierValidationRecord.supplierRegistrationProcess?.abaRouting && {
                      name: 'Registration-Form (ABA)',
                      value: removeCharsAndUpperCase(
                        supplierValidationRecord.supplierRegistrationProcess.abaRouting,
                        charsToIgnore,
                      ),
                      displayValue: supplierValidationRecord.supplierRegistrationProcess.abaRouting,
                    },
                    !supplierValidationRecord.supplierRegistrationProcess?.abaRouting && {
                      name: 'Registration-Form',
                      value: removeCharsAndUpperCase(
                        supplierValidationRecord.supplierRegistrationProcess?.accountDetails.bankCode,
                        charsToIgnore,
                      ),
                      displayValue: supplierValidationRecord.supplierRegistrationProcess?.accountDetails.bankCode,
                    },
                    {
                      name: `Evidence${evidenceData?.type === EvidenceType.finicityOpenBanking ? ' (Finicity)' : ''}`,
                      value: removeCharsAndUpperCase(evidenceData?.bankCode, charsToIgnore),
                      displayValue: evidenceData?.bankCode,
                    },
                  ].filter(isTruthy)}
                >
                  <NakedFormInput
                    onBlur={(e): void => upperCaseFieldValue(e, 'bankCode')}
                    name='inpt-verification-data-account-bank-code'
                    dataTestId='inpt-verification-data-account-bank-code'
                    type='text'
                    placeholder='Bank Code'
                  />
                </FormItemBoxWithSuggestions>
                <FormItemBoxWithSuggestions<string>
                  dataTestId='inpt-verification-data-account-branch-code-suggestion'
                  fieldName='branchCode'
                  fieldDecoratorOptions={fieldDecorators.branchCode}
                  suggestions={[
                    {
                      name: 'Registration-Form',
                      value: supplierValidationRecord.supplierRegistrationProcess?.accountDetails.branchCode?.toUpperCase(),
                      displayValue: supplierValidationRecord.supplierRegistrationProcess?.accountDetails.branchCode,
                    },
                    {
                      name: 'Evidence',
                      value: evidenceData?.branchCode?.toUpperCase(),
                      displayValue: evidenceData?.branchCode,
                    },
                  ]}
                >
                  <NakedFormInput
                    onBlur={(e): void => upperCaseFieldValue(e, 'branchCode')}
                    name='inpt-verification-data-account-branch-code'
                    dataTestId='inpt-verification-data-account-branch-code'
                    type='text'
                    placeholder='Branch Code'
                  />
                </FormItemBoxWithSuggestions>
                <FormItemBoxWithSuggestions
                  dataTestId='inpt-verification-data-account-iban-suggestion'
                  fieldName='iban'
                  fieldDecoratorOptions={fieldDecorators.iban}
                  equalityCheck={createEqualityCheckFunctionCaseInsensitiveIgnoredChars(charsToIgnore)}
                  suggestions={[
                    {
                      name: 'Registration-Form',
                      value:
                        removeCharsAndUpperCase(
                          supplierValidationRecord.supplierRegistrationProcess?.accountDetails.iban,
                          charsToIgnore,
                        ) ??
                        removeCharsAndUpperCase(
                          supplierValidationRecord.supplierRegistrationProcess?.accountDetails.accountNumber,
                          charsToIgnore,
                        ),
                      displayValue:
                        supplierValidationRecord.supplierRegistrationProcess?.accountDetails.iban ??
                        supplierValidationRecord.supplierRegistrationProcess?.accountDetails.accountNumber,
                    },
                    {
                      name: 'Evidence',
                      value: removeCharsAndUpperCase(evidenceData?.iban, charsToIgnore),
                      displayValue: evidenceData?.iban,
                    },
                    {
                      name: `Evidence${evidenceData?.type === EvidenceType.finicityOpenBanking ? ' (Finicity)' : ''}`,
                      value: removeCharsAndUpperCase(evidenceData?.accountNumber, charsToIgnore),
                      displayValue: evidenceData?.accountNumber,
                    },
                  ]}
                >
                  <NakedFormInput
                    onBlur={(e): void => upperCaseFieldValue(e, 'iban')}
                    name='inpt-verification-data-account-iban'
                    dataTestId='inpt-verification-data-account-iban'
                    type='text'
                    placeholder='IBAN'
                  />
                </FormItemBoxWithSuggestions>
                <FormItemBoxWithSuggestions
                  dataTestId='inpt-verification-data-account-swift-suggestion'
                  fieldName='swift'
                  fieldDecoratorOptions={fieldDecorators.swift}
                  equalityCheck={SWIFT_EQUALS_FUNCTION}
                  suggestions={[
                    {
                      name: 'Registration-Form',
                      value: supplierValidationRecord.supplierRegistrationProcess?.accountDetails.swiftCode?.toUpperCase(),
                      displayValue: supplierValidationRecord.supplierRegistrationProcess?.accountDetails.swiftCode,
                    },
                    {
                      name: 'Evidence',
                      value: evidenceData?.swiftCode?.toUpperCase(),
                      displayValue: evidenceData?.swiftCode,
                    },
                  ]}
                >
                  <NakedFormInput
                    onBlur={(e): void => upperCaseFieldValue(e, 'swift')}
                    name='inpt-verification-data-account-swift'
                    dataTestId='inpt-verification-data-account-swift'
                    type='text'
                    placeholder='SWIFT'
                  />
                </FormItemBoxWithSuggestions>
                <FormItemBoxWithSuggestions
                  dataTestId='inpt-verification-data-account-number-suggestion'
                  fieldName='accountNumber'
                  fieldDecoratorOptions={fieldDecorators.accountNumber}
                  equalityCheck={createEqualityCheckFunctionCaseInsensitiveIgnoredChars(charsToIgnore)}
                  suggestions={[
                    {
                      name: 'Registration-Form',
                      value: removeCharsAndUpperCase(
                        supplierValidationRecord.supplierRegistrationProcess?.accountDetails.accountNumber,
                        charsToIgnore,
                      ),
                      displayValue: supplierValidationRecord.supplierRegistrationProcess?.accountDetails.accountNumber,
                    },
                    {
                      name: 'Evidence (IBAN)',
                      value: removeCharsAndUpperCase(evidenceData?.iban, charsToIgnore),
                      displayValue: evidenceData?.iban,
                    },
                    {
                      name: `Evidence${evidenceData?.type === EvidenceType.finicityOpenBanking ? ' (Finicity)' : ''}`,
                      value: removeCharsAndUpperCase(evidenceData?.accountNumber, charsToIgnore),
                      displayValue: evidenceData?.accountNumber,
                    },
                  ]}
                >
                  <NakedFormInput
                    onBlur={(e): void => upperCaseFieldValue(e, 'accountNumber')}
                    name='inpt-verification-data-account-number'
                    dataTestId='inpt-verification-data-account-number'
                    type='text'
                    placeholder='Account Number'
                  />
                </FormItemBoxWithSuggestions>
                <FormItemBoxWithSuggestions
                  dataTestId='inpt-verification-data-account-further-credit-suggestion'
                  fieldName='furtherCredit'
                  fieldDecoratorOptions={fieldDecorators.furtherCredit}
                  suggestions={[
                    {
                      name: 'Registration-Form',
                      value: supplierValidationRecord.supplierRegistrationProcess?.furtherCredit?.toUpperCase(),
                      displayValue: supplierValidationRecord.supplierRegistrationProcess?.furtherCredit,
                    },
                  ]}
                >
                  <NakedFormInput
                    onBlur={(e): void => upperCaseFieldValue(e, 'furtherCredit')}
                    name='inpt-verification-data-account-further-credit'
                    dataTestId='inpt-verification-data-account-further-credit'
                    type='text'
                    placeholder='Further Credit'
                  />
                </FormItemBoxWithSuggestions>
                <FormItemBoxWithSuggestions
                  dataTestId='inpt-verification-data-account-local-format'
                  fieldName='localFormat'
                  fieldDecoratorOptions={fieldDecorators.localFormat}
                  hidden={!doesCountryHaveLocalFormat}
                  suggestions={[
                    {
                      name: 'Registration-Form',
                      value: removeCharsAndUpperCase(
                        supplierValidationRecord.supplierRegistrationProcess?.accountDetails.accountNumber,
                        charsToIgnore,
                      ),
                      displayValue: supplierValidationRecord.supplierRegistrationProcess?.accountDetails.accountNumber,
                    },
                  ]}
                >
                  <NakedFormInput
                    onBlur={(e): void => upperCaseFieldValue(e, 'localFormat')}
                    name='inpt-verification-data-account-local-format'
                    dataTestId='inpt-verification-data-account-local-format'
                    type='text'
                    placeholder='CLABE'
                  />
                </FormItemBoxWithSuggestions>
              </InputGrid>
              {isAccountCountryUS && <FieldsTitle>Receive Account?</FieldsTitle>}
              <TransparentFormItemBox
                shouldBeHidden={!isAccountCountryUS}
                appearance='none'
                fieldName='achDidReceiveAccount'
                fieldDecoratorOptions={fieldDecorators.achDidReceiveAccount}
                disabled={isPendingApprovalAndDoesntHaveApprovalRole}
              >
                <FormRadioGroup
                  accessibilityLabel='Did Receive Account'
                  id='radio-group-did-receive-account'
                  dataTestId='radio-group-did-receive-account'
                  options={[
                    {
                      value: AchAccount.yes,
                      label: 'Yes',
                    },
                    {
                      value: AchAccount.no,
                      label: 'No',
                    },
                    {
                      value: AchAccount.irrelevant,
                      label: 'Irrelevant',
                    },
                  ]}
                />
              </TransparentFormItemBox>
              {isAccountCountryUS && <FieldsTitle>Receive EIN?</FieldsTitle>}
              <TransparentFormItemBox
                shouldBeHidden={!isAccountCountryUS}
                appearance='none'
                fieldName='achDidReceiveEin'
                fieldDecoratorOptions={fieldDecorators.achDidReceiveEin}
                disabled={isPendingApprovalAndDoesntHaveApprovalRole}
              >
                <FormRadioGroup
                  accessibilityLabel='Did Receive Ein'
                  id='radio-group-did-receive-ein'
                  dataTestId='radio-group-did-receive-ein'
                  options={[
                    {
                      value: AchAccount.yes,
                      label: 'Yes',
                    },
                    {
                      value: AchAccount.no,
                      label: 'No',
                    },
                    {
                      value: AchAccount.irrelevant,
                      label: 'Irrelevant',
                    },
                  ]}
                />
              </TransparentFormItemBox>
            </div>
          </NsknoxForm>
        </CollapsePanelStickyHeader>
      </Collapse>
    );
  }

  return renderFormFields();
});

function createUpdateRequestAllUpdateFieldsFromFormFields(
  formFields: CreateVerificationDataRecordAccountFormFields,
): StoreSupplierValidationAccountVerificationRecordRequest {
  return {
    bankCode: trimToNull(formFields.bankCode),
    branchCode: trimToNull(formFields.branchCode),
    accountNumber: trimToNull(formFields.accountNumber),
    iban: trimToNull(formFields.iban),
    swift: trimToNull(formFields.swift),
    bankName: trimToNull(formFields.bankName),
    countryCode: trimToNull(formFields.countryCode),
    furtherCredit: trimToNull(formFields.furtherCredit),
    type: formFields.type ?? null,
    validationTimestamp: formFields.effectiveDate ?? null,
    achDidReceiveAccount: booleanOrNullFromAchAccount(formFields.achDidReceiveAccount),
    achDidReceiveEin: booleanOrNullFromAchAccount(formFields.achDidReceiveEin),
    localFormat: stringOrNullForLocalFormat(trimToNull(formFields.localFormat), formFields.countryCode),
  };
}

export default Form.create<Props>({})(VerificationRecordAccountForm);

function createFieldDecorators(
  verificationRecordAccountData: SupplierValidationAccountVerificationRecord | null,
): FormFieldDecorators<CreateVerificationDataRecordAccountFormFields> {
  return {
    countryCode: {
      initialValue: verificationRecordAccountData?.countryCode ?? undefined,
    },
    swift: {
      initialValue: verificationRecordAccountData?.swift ?? undefined,
      rules: [
        {
          validator: swiftCodeWithSuffixValidator,
          transform: trim,
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumeric,
          transform: trim,
          message: 'Invalid Character',
        },
      ],
      // trigger:
    },
    bankCode: {
      initialValue: verificationRecordAccountData?.bankCode ?? undefined,
      rules: [
        {
          max: SUPPLIER_VALIDATION_ACCOUNT_VERIFICATION_DATA_FIELD_MAX_LENGTH.bankCode,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_ACCOUNT_VERIFICATION_DATA_FIELD_MAX_LENGTH.bankCode} characters`,
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericWithChinese,
          transform: trim,
          message: 'Invalid Bank Code',
        },
      ],
    },
    bankName: {
      initialValue: verificationRecordAccountData?.bankName ?? undefined,
      rules: [
        {
          max: SUPPLIER_VALIDATION_ACCOUNT_VERIFICATION_DATA_FIELD_MAX_LENGTH.bankName,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_ACCOUNT_VERIFICATION_DATA_FIELD_MAX_LENGTH.bankName} characters`,
        },
      ],
    },
    branchCode: {
      initialValue: verificationRecordAccountData?.branchCode ?? undefined,
      rules: [
        {
          max: SUPPLIER_VALIDATION_ACCOUNT_VERIFICATION_DATA_FIELD_MAX_LENGTH.branchCode,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_ACCOUNT_VERIFICATION_DATA_FIELD_MAX_LENGTH.branchCode} characters`,
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericWithChinese,
          transform: trim,
          message: 'Invalid Branch Code',
        },
      ],
    },
    accountNumber: {
      initialValue: verificationRecordAccountData?.accountNumber ?? undefined,
      rules: [
        {
          max: SUPPLIER_VALIDATION_ACCOUNT_VERIFICATION_DATA_FIELD_MAX_LENGTH.accountNumber,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_ACCOUNT_VERIFICATION_DATA_FIELD_MAX_LENGTH.accountNumber} characters`,
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          transform: trim,
          message: 'Invalid Account Number',
        },
      ],
    },
    iban: {
      initialValue: verificationRecordAccountData?.iban ?? undefined,
      rules: [
        {
          transform: trim,
          validator: validateIban,
        },
        {
          max: SUPPLIER_VALIDATION_ACCOUNT_VERIFICATION_DATA_FIELD_MAX_LENGTH.iban,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_ACCOUNT_VERIFICATION_DATA_FIELD_MAX_LENGTH.iban} characters`,
        },
      ],
    },
    furtherCredit: {
      initialValue: verificationRecordAccountData?.furtherCredit ?? undefined,
      rules: [
        {
          max: SUPPLIER_VALIDATION_ACCOUNT_VERIFICATION_DATA_FIELD_MAX_LENGTH.furtherCredit,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_ACCOUNT_VERIFICATION_DATA_FIELD_MAX_LENGTH.furtherCredit} characters`,
        },
      ],
    },
    effectiveDate: {
      initialValue: verificationRecordAccountData?.validationTimestamp ?? undefined,
      rules: [
        {
          type: 'number',
        },
        {
          validator: notFutureTimestampValidator(),
        },
      ],
    },
    type: {
      initialValue: verificationRecordAccountData?.type ?? undefined,
    },
    achDidReceiveAccount: {
      initialValue: achAccountFromBoolean(verificationRecordAccountData?.achDidReceiveAccount),
    },
    achDidReceiveEin: {
      initialValue: achAccountFromBoolean(verificationRecordAccountData?.achDidReceiveEin),
    },
    localFormat: {
      initialValue:
        stringOrNullForLocalFormat(verificationRecordAccountData?.localFormat, verificationRecordAccountData?.countryCode) ??
        undefined,
      rules: [
        {
          len: ACCOUNT_FIELD_MAX_LENGTH.MX.clabe,
          transform: trim,
          message: `Must be exactly ${ACCOUNT_FIELD_MAX_LENGTH.MX.clabe} digits`,
        },
        {
          pattern: VALIDATION_PATTERNS.numeric,
          transform: trim,
          message: 'Invalid CLABE',
        },
      ],
    },
  };
}

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-auto-flow: row;
  align-items: start;
  grid-column-gap: 40px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
`;

const HeaderButton = styled(Button)`
  padding: 0 14px;
  margin-right: 8px;
`;

const ValidationTypeDropdownIItem = styled(DropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

const FieldsTitle = styled(H6StartTransparentBlack800.div)`
  margin-bottom: 8px;
`;

const StickyHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TransparentFormItemBox = styled(FormItemBox)<{ shouldBeHidden: boolean }>`
  ${(p): string => (p.shouldBeHidden ? 'display: none;' : '')}
`;
