import { PrivatePayeeServerResponse } from '@mortee/domain/privatePayee';
import ExtendedValidationLogo from '@app/images/validateResult/icons/extended.svg';
import StandardValidationLogo from '@app/images/validateResult/icons/standard.svg';
import SelfValidationLogo from '@app/images/validateResult/icons/self.svg';
import UnknownValidationLogo from '@app/images/validateResult/icons/unknown.svg';
import { AggregatedValidatedPayeeServerResponse } from '@app/domain/aggregatedValidatedPayee';
import {
  ValidatedAccountEventData,
  ValidatedPayeeAccountAttachment,
  ValidatedPayeeAccountEventDataServerResponse,
  ValidatedPayeeAccountServerResponse,
} from '@app/domain/validatedPayeeAccount';
import { PrivatePayeeAccountServerResponse } from '@mortee/domain/privatePayeeAccount';
import { UnifiedPayeeLegalEntityIdentifier } from '@mortee/domain/morteeAccountVerificationUnifiedPayee';
import { SanctionsScreeningInfo, ValidatedPayeePrimaryFields, ValidatedPayeeServerResponse } from '@app/domain/validatedPayee';
import { StrongLegalIdentifies } from '@app/domain/legalEntityIdentifier';
import { VerificationResultText } from '@app/domain/accountVerification';

export interface AccountVerificationPayeeSearchServerResponse {
  privateRecords?: PrivatePayeeServerResponse[];
  validatedPayeeData?: AggregatedValidatedPayeeServerResponse | null;
}

export interface MorteeVerificationServerResponse {
  validatedPayee: ValidatedPayeeServerResponse;
  validatedPayeeAccount: ValidatedPayeeAccountServerResponse;
  privatePayeeAccount: PrivatePayeeAccountServerResponse;
  validationData: VerificationDataServerResponse;
  aggregatedValidatedPayeeData: AggregatedValidatedPayeeServerResponse;
  lastValidatedPayeeAccountEventData: ValidatedAccountEventData | undefined;
}

export interface VerificationDataServerResponse {
  result: VerificationResultType;
}

export interface MorteeVerificationResult {
  data?: ValidatedPayeeAccountEventDataServerResponse;
  result: VerificationResultType;
  privateAccountUniformId?: string;
  validatedAccountUniformId?: string;
  validatedPayeeLegalIdentifiers?: UnifiedPayeeLegalEntityIdentifier[];
  strongLegalIdentifiers?: StrongLegalIdentifies;
  primaryFields?: ValidatedPayeePrimaryFields | null;
  verificationAccountDetails?: Partial<MorteeAccountDetailsExtended> | null;
  validatedAccountAttachments?: ValidatedPayeeAccountAttachment[] | undefined;
  sanctionsScreeningInfo?: SanctionsScreeningInfo | null;
  verificationTimestamp?: number | null;
}

export enum VerificationResultType {
  notValidated = 'NotValidated',
  selfApproved = 'SelfApproved',
  requireStandardValidation = 'RequireStandardValidation',
  requireExtendedValidation = 'RequireExtendedValidation',
  lightweightValidated = 'LightweightValidated',
  internalValidated = 'InternalValidated',
  reverseWireValidated = 'ReverseWireValidated',
  extendedValidated = 'ExtendedValidated',
  crowdKnowledge = 'CrowdKnowledge',
  externalBankValidated = 'ExternalBankValidated',
  lyonsValidated = 'LyonsValidated',
}

export const validationTypes = {
  [VerificationResultType.extendedValidated]: {
    logo: ExtendedValidationLogo,
    title: 'Extended Validation',
    description:
      'This is the most comprehensive verification. The process entails performing comprehensive validation of a vendor’s legal entity data and bank account information, including identification of the authorized signatories.',
    showDescriptionUnderResult: true,
  },
  [VerificationResultType.internalValidated]: {
    logo: StandardValidationLogo,
    title: 'Standard Validation',
    description:
      'As part of this validation, nsKnox analyzes a bank transfer from this account to verify the name of the account owner, its corporate identity number, as well as its geographic location. This verification ensures that the bank account was registered under the specified name.',
    showDescriptionUnderResult: false,
  },
  [VerificationResultType.selfApproved]: {
    logo: SelfValidationLogo,
    title: 'Self Validation',
    description:
      'Bank account details were validated by your organization due to small transaction amounts or due to internal assessment that the bank account details are correct.',
    showDescriptionUnderResult: false,
  },
  [VerificationResultType.notValidated]: {
    logo: UnknownValidationLogo,
    title: 'Not Validated',
    description: 'This account is not validated.',
    showDescriptionUnderResult: false,
  },
};

export const translateVerificationLevel = (verificationLevel: VerificationResultType): VerificationResultText => {
  switch (verificationLevel) {
    case VerificationResultType.extendedValidated: {
      return VerificationResultText.extendedValidation;
    }
    case VerificationResultType.lightweightValidated:
    case VerificationResultType.internalValidated:
    case VerificationResultType.externalBankValidated:
    case VerificationResultType.reverseWireValidated:
    case VerificationResultType.lyonsValidated: {
      return VerificationResultText.standardValidation;
    }
    case VerificationResultType.selfApproved: {
      return VerificationResultText.selfApproved;
    }
    case VerificationResultType.crowdKnowledge: {
      return VerificationResultText.crowdKnowledge;
    }
    case VerificationResultType.notValidated:
    case VerificationResultType.requireStandardValidation:
    case VerificationResultType.requireExtendedValidation: {
      return VerificationResultText.notValidated;
    }
    default: {
      return VerificationResultText.unknownResult;
    }
  }
};

export function morteeTransformVerifyPayeeAccount(verifyResponse: MorteeVerificationServerResponse): MorteeVerificationResult {
  return {
    data: verifyResponse.lastValidatedPayeeAccountEventData,
    result: verifyResponse.validationData.result,
    privateAccountUniformId: verifyResponse.privatePayeeAccount?.uniformId,
    validatedAccountUniformId: verifyResponse.validatedPayeeAccount?.uniformId,
    validatedPayeeLegalIdentifiers: verifyResponse.aggregatedValidatedPayeeData?.legalIdentifiers,
    strongLegalIdentifiers: verifyResponse.aggregatedValidatedPayeeData?.strongLegalIdentifies,
    primaryFields: verifyResponse.aggregatedValidatedPayeeData?.primaryFields ?? null,
    verificationAccountDetails: verifyResponse.validatedPayeeAccount
      ? {
          ...verifyResponse.validatedPayeeAccount.accountDetails,
          localFormat: verifyResponse?.validatedPayeeAccount?.localFormat,
        }
      : verifyResponse.privatePayeeAccount?.accountDetails,
    validatedAccountAttachments: verifyResponse.validatedPayeeAccount?.attachments,
    sanctionsScreeningInfo: verifyResponse.validatedPayee?.latestSanctionsScreeningEvent,
    verificationTimestamp: verifyResponse.validatedPayeeAccount?.lastValidationTimestamp,
  };
}

export interface ValidationEmailToCustomerValuesServerResponse {
  to: string[] | null | undefined;
  referringCustomerName: string | null | undefined;
}

export interface ValidationEmailToCustomerValues {
  to: string[] | undefined;
  referringCustomerName: string | undefined;
}

export function transformToCustomerValidationEmailValues(
  serverResponse: ValidationEmailToCustomerValuesServerResponse,
): ValidationEmailToCustomerValues {
  return {
    to: serverResponse.to ?? undefined,
    referringCustomerName: serverResponse.referringCustomerName ?? undefined,
  };
}

export interface SendValidationEmailToPayeeRequest {
  to: string[];
}

export interface SendValidationEmailToCustomerRequest {
  to: string[];
  referringCustomerName: string;
}
