import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { getRepresentationFormatterWithSwiftban } from '@app/domain/accountDetailsFormatters';
import { observer } from 'mobx-react';
import { getCountryByCountryCode } from '@app/domain/countries';
import DataGrid, { DataGridRow } from '../DataGrid';
import Nationality from '../Nationality';
import withPlaceholder from '../withPlaceholder';
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder';
import { ValidatedPayeeAccountToDisplay } from '@app/domain/validatedPayeeAccount';
import { useTranslation } from 'react-i18next';
import { convertTimestampToDefaultTimeString } from '@app/utils/timeUtils';
import LeftToRightText from '@app/components/LeftToRightText';

interface DataProps {
  payeeAccount: ValidatedPayeeAccountToDisplay;
  validationTimestamp: number | null;
}

interface DisplayProps {
  className?: string;
}

const VerificationValidatedAccountDetails: FunctionComponent<DisplayProps & DataProps> = observer((props) => {
  const { t } = useTranslation();

  const renderAccounts = (accountDetails: Partial<MorteeAccountDetails>): ReactElement => {
    const { accountNumber, bankCode, iban, swiftCode } = accountDetails;

    return (
      <>
        {swiftCode && accountNumber && (
          <DataGridRow title={t('verifyAccount.swiftLabel', 'Swift:')} valueDataTest='account-details-Swift'>
            <LeftToRightText text={getRepresentationFormatterWithSwiftban('swift')(payeeAccount.accountDetails)} />
          </DataGridRow>
        )}
        {iban && (
          <DataGridRow title={t('verifyAccount.ibanLabel', 'IBAN:')} valueDataTest='account-details-IBAN'>
            <LeftToRightText text={getRepresentationFormatterWithSwiftban('iban')(payeeAccount.accountDetails)} />
          </DataGridRow>
        )}
        {accountNumber && bankCode && (
          <DataGridRow title={t('verifyAccount.domesticLabel', 'Domestic:')} valueDataTest='account-details-Domestic'>
            <LeftToRightText text={getRepresentationFormatterWithSwiftban('domestic')(payeeAccount.accountDetails)} />
          </DataGridRow>
        )}
        {payeeAccount.furtherCredit && (
          <DataGridRow
            title={t('verifyAccount.furtherCreditLabel', 'For further credit:')}
            valueDataTest='account-details-For further credit'
          >
            {payeeAccount.furtherCredit}
          </DataGridRow>
        )}
      </>
    );
  };

  const renderAccountCountry = (accountCountryCode?: string | null): ReactNode => {
    if (!accountCountryCode && !getCountryByCountryCode(accountCountryCode)) {
      return;
    }

    return (
      <DataGridRow title={t('verifyAccount.countryLabel', 'Country:')} valueDataTest='account-country'>
        <Nationality countryCode={accountCountryCode} />
      </DataGridRow>
    );
  };

  const renderBankName = (bankName?: string | null): ReactNode => {
    if (!bankName) {
      return;
    }

    return (
      <DataGridRow title={t('verifyAccount.bankNameLabel', 'Bank name:')} valueDataTest='account-bank-name'>
        {bankName}
      </DataGridRow>
    );
  };

  const renderValidationDate = (validationTimestamp?: number | null): ReactNode => {
    if (!validationTimestamp) {
      return;
    }

    return (
      <DataGridRow title={t('verifyAccount.validationDate', 'Validation date:')} valueDataTest='account-validation-date'>
        <LeftToRightText text={convertTimestampToDefaultTimeString(validationTimestamp)} />
      </DataGridRow>
    );
  };

  const { payeeAccount, className, validationTimestamp } = props;

  const { bankName, accountDetails, bankCountryCode } = payeeAccount;
  const accountCountryCode = bankCountryCode ?? accountDetails.countryCode;

  return (
    <DataGrid className={className}>
      {renderBankName(bankName)}
      {renderAccountCountry(accountCountryCode)}
      {renderAccounts(accountDetails)}
      {renderValidationDate(validationTimestamp)}
    </DataGrid>
  );
});

const LoadingGuestAccountDetails: FunctionComponent<DisplayProps> = observer((props) => {
  const { t } = useTranslation();

  const renderAccounts = (): ReactElement => {
    return (
      <>
        <DataGridRow title={t('verifyAccount.swiftLabel', 'Swift:')} valueDataTest='account-details-Swift'>
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 220, margin: '0.2em' }} />
        </DataGridRow>
        <DataGridRow title={t('verifyAccount.ibanLabel', 'IBAN:')} valueDataTest='account-details-IBAN'>
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 220, margin: '0.2em' }} />
        </DataGridRow>
        <DataGridRow title={t('verifyAccount.domesticLabel', 'Domestic:')} valueDataTest='account-details-Domestic'>
          <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 220, margin: '0.2em' }} />
        </DataGridRow>
      </>
    );
  };

  const renderAccountCountry = (): ReactNode => {
    return (
      <DataGridRow title={t('verifyAccount.countryLabel', 'Country:')} valueDataTest='account-country'>
        <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 220, margin: '0.2em' }} />
      </DataGridRow>
    );
  };

  const renderBankName = (): ReactNode => {
    return (
      <DataGridRow title={t('verifyAccount.bankNameLabel', 'Bank name:')} valueDataTest='account-bank-name'>
        <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 220, margin: '0.2em' }} />
      </DataGridRow>
    );
  };

  const { className } = props;

  return (
    <DataGrid className={className}>
      {renderBankName()}
      {renderAccountCountry()}
      {renderAccounts()}
    </DataGrid>
  );
});

export default withPlaceholder<DisplayProps, DataProps>(VerificationValidatedAccountDetails, LoadingGuestAccountDetails);
