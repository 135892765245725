import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { observer } from 'mobx-react';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import VerificationValidatedPayeeDetails from '@app/components/verificationValidatedPayeeDetails/VerificationValidatedPayeeDetails';
import PageWithHeader from '@app/components/PageWithHeader';
import {
  BodySmallStartTransparentBlack600,
  Bold13TransparentBlack900,
  Regular22TransparentBlack900,
  SubtitleSmallStartTransparentBlack600,
} from '@app/components/Text';
import { wrapValueAsArray } from '@app/utils/arrayUtils';
import VerificationValidatedAccountDetails from '@app/components/verificationValidatedPayeeDetails/VerificationValidatedAccountDetails';
import withPlaceholder from '@app/components/withPlaceholder';
import DecryptingImage from '@app/images/bankAccountCertificate/bank-account-certificate-decrypting.svg';
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder';
import SVG from '@app/components/SVG';
import { BlockHeader } from '@app/components/DisplayFields';
import VerifyResultCard from '@mortee/routes/accountVerification/verifyAccount/VerifyResultCard';
import AccountVerificationAboveMainCardTitle from '@mortee/routes/accountVerification/AccountVerificationAboveMainCardTitle';
import { LegalEntityIdentifier, StrongLegalIdentifiesTypes } from '@app/domain/legalEntityIdentifier';
import { AccountVerificationBankAccountCertificateVerifyResult } from '@app/domain/bac';
import { ValidatedPayeeToDisplay } from '@app/domain/aggregatedValidatedPayee';
import { AccountAttachments } from '@app/components/accountVerification/AccountAttachments';
import { AttachmentSource, downloadAccountAttachment } from '@app/services/attachmentServices';
import SanctionsBadge, { PayeeTitle } from '@app/components/SanctionsBadge';

interface DataProps {
  verificationResult: AccountVerificationBankAccountCertificateVerifyResult;
}

interface DisplayProps {}

const AccountVerificationWithCertificateAfterVerificationPage: FC<DisplayProps & DataProps> = observer((props) => {
  useInnerPageEnterAnalyticsReport(InnerPage.verifyWithCertificateResult);
  const { verificationResult } = props;

  const payeeToDisplay: ValidatedPayeeToDisplay = {
    uniformId: null,
    mainName: verificationResult.payeeData.mainName,
    names: verificationResult.payeeData.otherNames,
    addresses: wrapValueAsArray(verificationResult.payeeData.address),
    countryCode: verificationResult.payeeData.countryCode,
    strongLegalIdentifies: {
      [StrongLegalIdentifiesTypes.KnoxId]: wrapValueAsArray<LegalEntityIdentifier>(verificationResult.payeeData.knoxId),
      [StrongLegalIdentifiesTypes.DUNS]: wrapValueAsArray<LegalEntityIdentifier>(verificationResult.payeeData.duns),
      [StrongLegalIdentifiesTypes.TaxId]: verificationResult.payeeData.taxIds,
    },
    legalIdentifiers: verificationResult.payeeData.regularLegalIds,
    emails: [],
    phones: [],
    faxes: [],
    websites: [],
    primaryFields: null,
  };

  const renderPayeeUpdatedWarning = (): ReactElement => {
    return (
      <PayeeUpdatedWarning data-testid='payee-updated-warning'>
        The payee / account information might have updated since this BAC was created.
        <br />
        In any way, this portal presents the most updated information:
      </PayeeUpdatedWarning>
    );
  };

  return (
    <PageWithHeader paddingTop={52} width={850} widthReactive={false}>
      <PageContainer>
        <AccountVerificationAboveMainCardTitle title='Certificate Verification' showBackButton />
        <ResultsCard>
          {verificationResult.isPayeeUpdated && renderPayeeUpdatedWarning()}
          <DataFromCertificateExplanation>Bank Account Certificate Details:</DataFromCertificateExplanation>
          <PayeeTitle>
            <Regular22TransparentBlack900.div>{payeeToDisplay.mainName}</Regular22TransparentBlack900.div>
            <SanctionsBadge sanctionsScreeningInfo={verificationResult?.sanctionsScreeningInfo} />
          </PayeeTitle>
          <CertificateDetailsGrid>
            <div>
              <CertificateDetailsTitle>PAYEE DETAILS</CertificateDetailsTitle>
              <VerificationValidatedPayeeDetails payee={payeeToDisplay} hideMainName />
            </div>
            <div>
              <CertificateDetailsTitle>BANK ACCOUNT DETAILS</CertificateDetailsTitle>
              <VerificationValidatedAccountDetails
                payeeAccount={verificationResult.accountData}
                validationTimestamp={verificationResult.validationTimestamp}
              />
            </div>
          </CertificateDetailsGrid>
          <AccountAttachments
            validatedAccountAttachments={verificationResult.validatedPayeeAccountAttachments}
            downloadHandler={downloadAccountAttachment}
            source={AttachmentSource.verification}
          />
        </ResultsCard>
        <div>
          <BlockHeader>Verification Result</BlockHeader>
          <VerifyResultCard verificationDetails={verificationResult.validationResult} />
        </div>
      </PageContainer>
    </PageWithHeader>
  );
});

const LoadingVerificationWithCertificateAfterVerificationPage: FC<DisplayProps> = () => {
  return (
    <PageWithHeader paddingTop={52} width={850} widthReactive={false}>
      <PageContainer>
        <AccountVerificationAboveMainCardTitle title='Certificate Verification' showBackButton />
        <ResultsCard>
          <DecryptingIcon>
            <SVG accessibilityLabel='' image={DecryptingImage} width={61} height={59} />
          </DecryptingIcon>
          <DecryptingText>Decoding certificate data…</DecryptingText>
          <Regular22TransparentBlack900.div>
            <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 230, margin: '0.2em' }} />
          </Regular22TransparentBlack900.div>
          <CertificateDetailsGrid>
            <div>
              <CertificateDetailsTitle>PAYEE DETAILS</CertificateDetailsTitle>
              <VerificationValidatedPayeeDetails loading hideMainName />
            </div>
            <div>
              <CertificateDetailsTitle>BANK ACCOUNT DETAILS</CertificateDetailsTitle>
              <VerificationValidatedPayeeDetails loading />
            </div>
          </CertificateDetailsGrid>
        </ResultsCard>
      </PageContainer>
    </PageWithHeader>
  );
};

export default withPlaceholder<DisplayProps, DataProps>(
  AccountVerificationWithCertificateAfterVerificationPage,
  LoadingVerificationWithCertificateAfterVerificationPage,
);

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResultsCard = styled(Card)`
  font-family: var(--text-font-family);

  width: 100%;
  padding: 24px 32px;
  margin-bottom: 36px;
`;

const DataFromCertificateExplanation = styled(BodySmallStartTransparentBlack600.div)`
  margin-bottom: 16px;
`;

const PayeeUpdatedWarning = styled(Bold13TransparentBlack900.div)`
  margin-bottom: 16px;
`;

const CertificateDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  margin-top: 30px;
`;

const CertificateDetailsTitle = styled(SubtitleSmallStartTransparentBlack600.div)`
  margin-bottom: 18px;
`;

const DecryptingIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
`;

const DecryptingText = styled(Bold13TransparentBlack900.div)`
  text-align: center;
  margin-bottom: 80px;
`;
