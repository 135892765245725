import React, { FC } from 'react';
import { ValidatedPayeeAccount } from '@app/domain/validatedPayeeAccount';
import {
  getRepresentationNameWithBIC,
  splitAccountDetailsIntoRepresentationsMap,
} from '@app/domain/accountDetailsRepresentations';
import { getRepresentationFormatterWithBIC } from '@app/domain/accountDetailsFormatters';
import CategoryAndValues from '@app/components/CategoryAndValues';
import styled from '@emotion/styled';
import Nationality from '@app/components/Nationality';

interface ValidatedPayeeAccountDetailsProps {
  validatedPayeeAccount: ValidatedPayeeAccount;
}

const ValidatedPayeeAccountDetails: FC<ValidatedPayeeAccountDetailsProps> = ({ validatedPayeeAccount }) => {
  const representationsMapToShow = splitAccountDetailsIntoRepresentationsMap(
    { ...validatedPayeeAccount.accountDetails, localFormat: validatedPayeeAccount.localFormat ?? null },
    false,
    true,
  );

  return (
    <MainContainer>
      <AccountInfoList data-testid='validated-payee-account-modal'>
        {validatedPayeeAccount.bankName && (
          <StyledCategoryAndValues label='Bank name' inlineLabel>
            {validatedPayeeAccount.bankName}
          </StyledCategoryAndValues>
        )}
        {Array.from(representationsMapToShow.entries()).map(
          ([type, representation]: [AccountRepresentationType, MorteeRepresentationAccountDetails]) => (
            <StyledCategoryAndValues
              data-testid={`validated-payee-account-modal-${type}`}
              key={type}
              label={getRepresentationNameWithBIC(type)}
              inlineLabel
            >
              {getRepresentationFormatterWithBIC(type)(representation)}
            </StyledCategoryAndValues>
          ),
        )}
      </AccountInfoList>
      {validatedPayeeAccount.bankCountryCode && (
        <StyledNationality countryCode={validatedPayeeAccount.bankCountryCode} unknownFlagStyle='wavyFlag' />
      )}
    </MainContainer>
  );
};

export default ValidatedPayeeAccountDetails;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const AccountInfoList = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  & > *:not(:first-child) {
    margin-top: 6px;
  }
`;

const StyledCategoryAndValues = styled(CategoryAndValues)`
  font-size: 13px;
  letter-spacing: 0.36px;
  color: var(--black-weak);
`;

const StyledNationality = styled(Nationality)`
  font-weight: 200;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.33px;
  color: var(--transparent-black-700);
`;
