import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { isDefined } from '@app/utils/utils';
import ValidatedPayeeDetails from '@mortee/components/validatedPayee/ValidatedPayeeDetails';
import { SupplierValidationPayeeVerificationRecord, SupplierValidationRecord } from '@mortee/domain/validationSystem';
import { ValidatedPayeeToDisplay } from '@app/domain/aggregatedValidatedPayee';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import VerificationEntityChangesBullets, {
  EntityChangeMessage,
} from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/reviewModals/VerificationEntityChangesBullets';
import { H4StartTransparentBlack600 } from '@app/components/Text';
import StoreConfirmationAlerts from '@mortee/routes/validatedPayeesManagement/StoreConfirmationAlerts';

interface Props {
  payee: SupplierValidationPayeeVerificationRecord;
  renderedWarnings: NonNullable<ReactNode>[];
  supplierValidationRecord: SupplierValidationRecord;
  className?: string;
}

const VerificationPayeeChanges: FC<Props> = ({ payee, renderedWarnings, supplierValidationRecord, className }) => {
  const { navigationStore } = useInfraStores<MorteeMode>();

  const organizationName = supplierValidationRecord.organization?.name || '';

  function calculatePayeeDataForDisplay(payeeData: SupplierValidationPayeeVerificationRecord): ValidatedPayeeToDisplay {
    const names = [payeeData.name, payeeData.additionalName].filter(isDefined);
    const addresses = [payeeData.address, payeeData.additionalAddress].filter(isDefined);
    return {
      countryCode: payeeData.countryCode ?? undefined,
      addresses: addresses,
      names: names,
      faxes: [],
      emails: [],
      websites: [],
      phones: [],
      descriptions: [],
      mainName: payeeData.name ?? undefined,
      uniformId: null,
      primaryFields: {
        name: names[0],
        address: addresses[0],
        legalIdentifier: payeeData.legalEntityIdentifiers?.[0],
      },
      sanctionsScreeningStatusResult: payeeData.sanctionsScreening?.result,
      sanctionsScreeningInfo: {
        timestamp: payeeData.sanctionsScreening?.screeningTimestamp ?? null,
        results: null,
      },
      legalIdentifiers: payeeData.legalEntityIdentifiers ?? [],
      strongLegalIdentifies: payeeData.strongLegalIdentifies ?? undefined,
      effectiveDate: payeeData.effectiveTimestamp ?? null,
    };
  }

  function calculatePayeeChangeMessages(payeeData: SupplierValidationPayeeVerificationRecord): EntityChangeMessage[] {
    const { isPrivate, alreadyExistingValidatedPayee, isUpdateRequired } = payeeData;

    if (!alreadyExistingValidatedPayee) {
      if (isPrivate) {
        return [
          {
            type: 'regular',
            content: (
              <span>
                Visibility: <ImportantText>Organizational visibility</ImportantText> only for "{organizationName}"
              </span>
            ),
          },
        ];
      }

      return [
        {
          type: 'regular',
          content: `Visibility: All users visibility`,
        },
      ];
    }

    if (isUpdateRequired) {
      return [
        {
          type: 'notice',
          content: (
            <div key={alreadyExistingValidatedPayee.uniformId}>
              The payee {alreadyExistingValidatedPayee.displayName} is <ImportantText>not</ImportantText> going to be
              automatically updated. You need to do it using the update form via the link:{' '}
              <a
                target='_blank'
                rel='noreferrer'
                href={navigationStore.generateEditValidatedPayeePageHref(alreadyExistingValidatedPayee.uniformId)}
              >
                {alreadyExistingValidatedPayee.displayName}
              </a>
            </div>
          ),
        },
      ];
    }

    return [
      {
        type: 'notice',
        content: (
          <div key={alreadyExistingValidatedPayee.uniformId}>
            The payee{' '}
            <a
              target='_blank'
              rel='noreferrer'
              href={navigationStore.generateManageValidatedPayeePageHref(alreadyExistingValidatedPayee.uniformId)}
            >
              {alreadyExistingValidatedPayee.displayName}
            </a>{' '}
            is <ImportantText>not</ImportantText> going to be updated
          </div>
        ),
      },
    ];
  }

  const showPayeeDetails = !payee?.alreadyExistingValidatedPayee;

  return (
    <PayeeEntityCard className={className}>
      <EntityCardHeader>
        {payee.alreadyExistingValidatedPayee ? 'Existing' : 'New'} Payee -{' '}
        {payee.alreadyExistingValidatedPayee?.displayName ?? payee.name}
      </EntityCardHeader>
      <VerificationEntityChangesBullets entityChangeMessages={calculatePayeeChangeMessages(payee)} />
      <AlertsBox title='Warnings:' alerts={renderedWarnings} data-testid='verification-payee-confirmation-modal-alert-box' />
      {showPayeeDetails && (
        <PaddedValidatedPayeeDetails
          data={calculatePayeeDataForDisplay(payee)}
          hideMainName
          moreInfoHideable={false}
          data-testid='verification-payee-card-confirmation-modal'
        />
      )}
    </PayeeEntityCard>
  );
};

export default VerificationPayeeChanges;

const ImportantText = styled.span`
  font-weight: 800;
  color: var(--transparent-black-800);
`;

const PayeeEntityCard = styled.div`
  // Horizontal padding will be applied by the content
  padding: 16px 0;

  border-radius: 8px;
  background: white;
`;

const PaddedValidatedPayeeDetails = styled(ValidatedPayeeDetails)`
  padding: var(--card-padding);
  padding-top: 0;
`;

const EntityCardHeader = styled(H4StartTransparentBlack600.div)`
  padding: var(--card-padding);
`;

const AlertsBox = styled(StoreConfirmationAlerts)`
  background: var(--warning-color);
  padding: 16px 16px 16px 0;
  margin: 16px;
`;
