import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { SupplierRegistrationProcess } from '@mortee/domain/morteeRegistrationForms';
import styled from '@emotion/styled';
import { BodyRegularStartTransparentBlack600, Regular13TransparentBlack600 } from '@app/components/Text';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import useForm from '@app/hooks/useForm';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import FormItemBox from '@app/components/inputs/FormItemBox';
import { FormFieldDecorators } from '@app/utils/form/form';
import { trim, trimToNull } from '@app/utils/stringUtils';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import DropdownItem from '@app/components/inputs/DropdownItem';
import * as messageLauncher from '@app/utils/messageLauncher';
import { SUPPLIER_VALIDATION_FIELD_MAX_LENGTH } from '@mortee/domain/validationSystemFields';
import AsyncButton from '@app/components/AsyncButton';
import Log from '@app/libs/logger';
import validationSystemServices from '@mortee/services/validationSystemServices';
import {
  createSupplierValidationRecordInfoAllUpdateFieldsFromRecord,
  displayDistributionWarningMessage,
  noOrgOption,
  NULL_ORG,
  SupplierValidationRecord,
  supplierValidationRecordAllUpdateFieldsEquators,
  SupplierValidationRecordInfoUpdateFields,
  SupplierValidationRecordInstructionType,
  transformSupplierValidationRecord,
  ValidationSystemOrganization,
} from '@mortee/domain/validationSystem';
import Loadable from '@app/utils/Loadable';
import { VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import CountryDropdown from '@mortee/routes/validatedPayeesManagement/CountryDropdown';
import Toggle from '@app/components/inputs/Toggle';
import NakedCalendarDatePicker from '@app/components/inputs/NakedCalendarDatePicker';
import useInfraStores from '@app/hooks/useInfraStores';
import { doesPartialHasAllRequiredFields } from '@app/utils/utils';
import SelectInstructionsInput from '@mortee/routes/validationSystem/instructionsEdit/SelectInstructionsInput';
import useFormChangesStatus from '@app/hooks/useFormChangesStatus';
import { compare } from '@app/utils/comparatorUtils';
import VerticalShadowScroller from '@app/components/VerticalShadowScroller';
import {
  ActionsContainer,
  SidePadding,
  TabTitle,
} from '@mortee/routes/validationSystem/editValidtionRecord/EditValidationRecordStyles';

interface EditValidationRecordInfoFormFields {
  startDate: number | null;
  organizationId: string;
  supplierName: string;
  companyCode?: string;
  country?: string;
  taxId?: string;
  knoxId?: string;
  isCustomerInvolvementRequired: boolean;
  firstContactTimestamp?: number;
  nsKnoxTransferTimestamp?: number;
  payeeTransferTimestamp?: number;
  manualCompletionDate?: number;
  manualInstructionType?: SupplierValidationRecordInstructionType | null;
}

interface Props extends FormComponentProps<EditValidationRecordInfoFormFields> {
  record: SupplierValidationRecord;
  allOrganizationsLoadable: Loadable<ValidationSystemOrganization[]>;
  className?: string;
  onSaved(updatedRecord: SupplierValidationRecord, userWantsToClose: boolean): void;
  onIsFormUnsavedChanged(isFormUnsaved: boolean | null): void;
}

const EditValidationRecordGeneralInfoTab: FC<Props> = observer((props) => {
  const { record, allOrganizationsLoadable, onSaved, onIsFormUnsavedChanged, className } = props;
  const { userStore } = useInfraStores();

  const { form, showFormErrors, setShowFormErrors, validateFields, isFormInvalid } = useForm(props);
  const [isSaving, setIsSaving] = useState(false);

  const validationRecordBaseData = useMemo((): SupplierValidationRecordInfoUpdateFields => {
    return createSupplierValidationRecordInfoAllUpdateFieldsFromRecord(record);
  }, [record]);

  const { amountOfChanges, updateRequest } = useFormChangesStatus(
    validationRecordBaseData,
    form,
    createUpdateRequestInfoUpdateFieldsFromFormFields,
    supplierValidationRecordAllUpdateFieldsEquators,
  );

  const doesFormHaveAnyChanges = !!amountOfChanges;

  useEffect(() => {
    onIsFormUnsavedChanged(doesFormHaveAnyChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- onIsFormUnsavedChanged might change on every render
  }, [doesFormHaveAnyChanges]);

  async function onSave(userWantsToClose: boolean): Promise<void> {
    setIsSaving(true);

    try {
      const formFields = await validateFields();
      const formFieldsAllUpdateFields = createUpdateRequestInfoUpdateFieldsFromFormFields(formFields);

      if (!formFieldsAllUpdateFields || !updateRequest) {
        throw new Error('The form is still not valid');
      }

      if (updateRequest.organizationId?.content === NULL_ORG.id) {
        updateRequest.organizationId.content = null;
      }
      const serverResponse = await validationSystemServices.updateValidationRecord(record.staticId, updateRequest);
      displayDistributionWarningMessage(serverResponse.warnings);
      const updatedRecord = transformSupplierValidationRecord(serverResponse.record, userStore.user?.id);

      messageLauncher.shoot(
        { type: 'success', duration: 4, closeable: true },
        `Validation ${serverResponse?.record.presentationId} saved successfully`,
      );
      onSaved(updatedRecord, userWantsToClose);
    } catch (e) {
      Log.exception(e);
    } finally {
      setIsSaving(false);
    }
  }

  const fieldDecorators = createFieldDecorators(record);

  function renderOrganizationsDropdown(): ReactElement {
    return (
      <NakedDropdown
        accessibilityLabel='organization'
        name='cbox-edit-validation-process-organization'
        dataTestId='cbox-edit-validation-process-organization'
        placeholder='Customer Name'
        isSearchable
        loading={allOrganizationsLoadable.isInProgress()}
      >
        {allOrganizationsLoadable.resolve<ReactElement[]>(
          (allOrganizations) =>
            noOrgOption
              .concat(allOrganizations)
              .sort(compare.byStringField((record) => record.name))
              .map(
                (org): ReactElement => {
                  return (
                    <DropdownItem key={org.id} value={org.id} textWhenSelected={org.name} keywords={[org.id, org.name]}>
                      <div>{org.name}</div>
                    </DropdownItem>
                  );
                },
              ),
          (): ReactElement[] => [],
        )}
      </NakedDropdown>
    );
  }

  return (
    <StyledNsknoxForm form={form} showErrors={showFormErrors} className={className} setShowErrors={setShowFormErrors}>
      <FullHeightVerticalShadowScroller>
        <Content>
          <TabTitle>General Info</TabTitle>
          <FieldLine>
            <FieldLabel>Current Instruction:</FieldLabel>
            <FormItemBox
              fieldName='manualInstructionType'
              fieldDecoratorOptions={fieldDecorators.manualInstructionType}
              appearance='none'
              expandBelowOnError
            >
              <SelectInstructionsInput
                accessibilityLabel='Change instruction type'
                id='select-edit-validation-process-manual-instruction-type'
                dataTestId='select-edit-validation-process-manual-instruction-type'
                instructionFromRegistrationForm={record.supplierRegistrationProcess?.instructionType}
              />
            </FormItemBox>
          </FieldLine>
          <FieldLine>
            <FieldLabel>Customer Involvement Required:</FieldLabel>
            <FormItemBox
              fieldName='isCustomerInvolvementRequired'
              fieldDecoratorOptions={fieldDecorators.isCustomerInvolvementRequired}
              appearance='none'
              expandBelowOnError
            >
              <Toggle
                accessibilityLabel='Toggle Customer Involvement Required'
                name='toggle-edit-validation-process-is-customer-involvement-required'
              />
            </FormItemBox>
          </FieldLine>
          <SectionTitle>Supplier Info</SectionTitle>
          <FormItemBox fieldName='organizationId' fieldDecoratorOptions={fieldDecorators.organizationId}>
            {renderOrganizationsDropdown()}
          </FormItemBox>
          <FormItemBox fieldName='supplierName' fieldDecoratorOptions={fieldDecorators.supplierName}>
            <NakedFormInput
              name='inpt-edit-validation-process-supplier-name'
              dataTestId='inpt-edit-validation-process-supplier-name'
              type='text'
              placeholder='Supplier Name'
            />
          </FormItemBox>
          <FormItemBox fieldName='startDate' fieldDecoratorOptions={fieldDecorators.startDate}>
            <NakedCalendarDatePicker
              disableFutureDates
              placeholder='Manual Start Date'
              id='date-picker-edit-validation-process-start-date'
            />
          </FormItemBox>
          <FormItemBox fieldName='companyCode' fieldDecoratorOptions={fieldDecorators.companyCode}>
            <NakedFormInput
              name='inpt-edit-validation-process-company-code'
              dataTestId='inpt-edit-validation-process-company-code'
              type='text'
              placeholder='Company Code'
            />
          </FormItemBox>
          <SectionTitle>Completion Info</SectionTitle>
          <FormItemBox fieldName='country' fieldDecoratorOptions={fieldDecorators.country}>
            <CountryDropdown
              accessibilityLabel='country'
              name='drp-edit-validation-process-country'
              dataTestId='drp-edit-validation-process-country'
              customPlaceholder='Manual Country'
            />
          </FormItemBox>
          <FormItemBox fieldName='taxId' fieldDecoratorOptions={fieldDecorators.taxId}>
            <NakedFormInput
              name={`inpt-edit-validation-process-tax-id`}
              dataTestId={`inpt-edit-validation-process-tax-id`}
              type='text'
              placeholder='Tax Id'
              disableSuggestion
            />
          </FormItemBox>
          <FormItemBox fieldName='knoxId' fieldDecoratorOptions={fieldDecorators.knoxId}>
            <NakedFormInput
              name={`inpt-edit-validation-process-knox-id`}
              dataTestId={`inpt-edit-validation-process-knox-id`}
              type='text'
              placeholder='Knox Id'
              disableSuggestion
            />
          </FormItemBox>
          <SectionTitle>Communication Details</SectionTitle>
          <FormItemBox fieldName='firstContactTimestamp' fieldDecoratorOptions={fieldDecorators.firstContactTimestamp}>
            <NakedCalendarDatePicker
              placeholder='First Contact At'
              id='date-picker-edit-validation-process-first-content-timestamp'
            />
          </FormItemBox>
          <FormItemBox fieldName='manualCompletionDate' fieldDecoratorOptions={fieldDecorators.manualCompletionDate}>
            <NakedCalendarDatePicker
              placeholder='Manual Completion Date'
              id='date-picker-edit-validation-process-completion-date'
            />
          </FormItemBox>
          <FormItemBox fieldName='nsKnoxTransferTimestamp' fieldDecoratorOptions={fieldDecorators.nsKnoxTransferTimestamp}>
            <NakedCalendarDatePicker
              placeholder='nsKnox Payment Transfer Date'
              id='date-picker-edit-validation-process-nsknox-transfer-timestamp'
            />
          </FormItemBox>
          <FormItemBox fieldName='payeeTransferTimestamp' fieldDecoratorOptions={fieldDecorators.payeeTransferTimestamp}>
            <NakedCalendarDatePicker
              placeholder='Effective Date'
              id='date-picker-edit-validation-process-payee-transfer-timestamp'
            />
          </FormItemBox>
        </Content>
      </FullHeightVerticalShadowScroller>
      <ActionsContainer>
        <StyledSaveButton
          id='btn-edit-validation-process-save-and-close'
          onClick={(): Promise<void> => onSave(true)}
          disabled={isFormInvalid || isSaving || !doesFormHaveAnyChanges}
          onDisabledClick={(): void => setShowFormErrors('all')}
          appearance='text'
        >
          SAVE AND CLOSE
        </StyledSaveButton>
        <StyledSaveButton
          id='btn-edit-validation-process-save'
          onClick={(): Promise<void> => onSave(false)}
          disabled={isFormInvalid || isSaving || !doesFormHaveAnyChanges}
          onDisabledClick={(): void => setShowFormErrors('all')}
        >
          SAVE
        </StyledSaveButton>
      </ActionsContainer>
    </StyledNsknoxForm>
  );
});

function createUpdateRequestInfoUpdateFieldsFromFormFields(
  formFields: Partial<EditValidationRecordInfoFormFields>,
): SupplierValidationRecordInfoUpdateFields | null {
  const onlyFieldsThatShouldBeNonNull: PartialNotOptional<NonNullableFields<EditValidationRecordInfoFormFields>> = {
    organizationId: formFields.organizationId,
    supplierName: formFields.supplierName,
    isCustomerInvolvementRequired: formFields.isCustomerInvolvementRequired,
  };

  // Some fields that should be not null are not ready at the loading of the form
  // This function makes sure that these required fields are there
  if (!doesPartialHasAllRequiredFields<EditValidationRecordInfoFormFields>(formFields, onlyFieldsThatShouldBeNonNull)) {
    return null;
  }

  return {
    startDate: formFields.startDate ?? null,
    organizationId: formFields.organizationId,
    supplierName: trim(formFields.supplierName),
    companyCode: trimToNull(formFields.companyCode),
    isCustomerInvolvementRequired: formFields.isCustomerInvolvementRequired ?? null,
    firstContactTimestamp: formFields.firstContactTimestamp ?? null,
    nsKnoxTransferTimestamp: formFields.nsKnoxTransferTimestamp ?? null,
    payeeTransferTimestamp: formFields.payeeTransferTimestamp ?? null,
    taxId: trimToNull(formFields.taxId),
    knoxId: trimToNull(formFields.knoxId),
    manualCountryCode: formFields.country ?? null,
    manualCompletionDate: formFields.manualCompletionDate ?? null,
    manualInstructionType: formFields.manualInstructionType ?? null,
  };
}

function createFieldDecorators(record: SupplierValidationRecord): FormFieldDecorators<EditValidationRecordInfoFormFields> {
  return {
    startDate: {
      initialValue: record.startDate,
      rules: [
        {
          required: false,
          message: `Please select a start date`,
        },
      ],
    },
    organizationId: {
      initialValue: record.organization?.id ?? NULL_ORG.id,
      rules: [
        {
          required: false,
          transform: trim,
          message: `Please select a customer`,
        },
        {
          validator: (rule, value, callback): void => {
            if (!value) {
              callback();
              return;
            }

            const linkedSupplierRegistration: SupplierRegistrationProcess | null = record.supplierRegistrationProcess;

            if (!linkedSupplierRegistration?.organizationId) {
              callback();
              return;
            }

            if (linkedSupplierRegistration.organizationId !== value) {
              callback('Customer name does not match referring customer of the linked registration forms');
              return;
            }

            callback();
            return;
          },
        },
      ],
    },
    supplierName: {
      initialValue: record.supplierName ?? undefined,
      rules: [
        {
          type: 'string',
          required: true,
          transform: trim,
          message: `Please enter a supplier name`,
        },
        {
          max: SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.supplierName,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.supplierName} characters`,
        },
      ],
    },
    companyCode: {
      initialValue: record.companyCode ?? undefined,
      rules: [
        {
          max: SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.companyCode,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.companyCode} characters`,
        },
      ],
    },
    country: {
      initialValue: record.manualCountryCode ?? undefined,
    },
    taxId: {
      initialValue: record.taxId ?? undefined,
    },
    knoxId: {
      initialValue: record.knoxId ?? undefined,
      rules: [
        {
          pattern: VALIDATION_PATTERNS.fullKnoxId,
          transform: trim,
          message: 'Invalid Knox Id',
        },
      ],
    },
    isCustomerInvolvementRequired: {
      initialValue: record.isCustomerInvolvementRequired,
      rules: [
        {
          required: true,
          message: 'Missing Value',
        },
        {
          type: 'boolean',
          message: 'Invalid Value',
        },
      ],
    },
    firstContactTimestamp: {
      initialValue: record.firstContactTimestamp,
    },
    nsKnoxTransferTimestamp: {
      initialValue: record.nsKnoxTransferTimestamp,
    },
    payeeTransferTimestamp: {
      initialValue: record.payeeTransferTimestamp,
    },
    manualCompletionDate: {
      initialValue: record.manualCompletionDate,
      rules: [
        {
          required: false,
          message: `Please select a completion date`,
        },
      ],
    },
    manualInstructionType: {
      initialValue: record.manualInstructionType,
    },
  };
}

export default Form.create<Props>()(EditValidationRecordGeneralInfoTab);

const StyledNsknoxForm = styled(NsknoxForm)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const FullHeightVerticalShadowScroller = styled(VerticalShadowScroller)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Content = styled(SidePadding)`
  flex: 1;
`;

const StyledSaveButton = styled(AsyncButton)`
  padding: 9px 43px;
  margin-left: 17px;
`;

const FieldLine = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 4px;
`;

const FieldLabel = styled(BodyRegularStartTransparentBlack600.div)`
  // To align with the form item top margin
  margin-top: 8px;
`;

const SectionTitle = styled.h2`
  ${Regular13TransparentBlack600.css};

  padding-top: 30px;
`;
