import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { observer } from 'mobx-react';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import { GuestBankAccountCertificateVerifyResult } from '@ar/domain/arAccountVerification';
import VerificationValidatedPayeeDetails from '@app/components/verificationValidatedPayeeDetails/VerificationValidatedPayeeDetails';
import PageWithHeader from '@app/components/PageWithHeader';
import {
  BodySmallStartTransparentBlack600,
  Bold13TransparentBlack900,
  Regular22TransparentBlack900,
  SubtitleSmallStartTransparentBlack600,
} from '@app/components/Text';
import JoinARBanner from '../JoinARBanner';
import { wrapValueAsArray } from '@app/utils/arrayUtils';
import GuestAccountDetails from '@app/components/verificationValidatedPayeeDetails/VerificationValidatedAccountDetails';
import ARAboveMainCardTitle from '../ARAboveMainCardTitle';
import VerificationResultCard from '../VerificationResultCard';
import withPlaceholder from '@app/components/withPlaceholder';
import DecryptingImage from '@app/images/bankAccountCertificate/bank-account-certificate-decrypting.svg';
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder';
import SVG from '@app/components/SVG';
import { LegalEntityIdentifier, StrongLegalIdentifiesTypes } from '@app/domain/legalEntityIdentifier';
import { ValidatedPayeeToDisplay } from '@app/domain/aggregatedValidatedPayee';
import { AttachmentSource, downloadAccountAttachment } from '@app/services/attachmentServices';
import { AccountAttachments } from '@app/components/accountVerification/AccountAttachments';
import { Trans, useTranslation } from 'react-i18next';
import SanctionsBadge, { PayeeTitle } from '@app/components/SanctionsBadge';
import useInfraStores from '@app/hooks/useInfraStores';
import ArMode from '@ar/arMode';

interface DataProps {
  verificationResult: GuestBankAccountCertificateVerifyResult;
}

interface DisplayProps {}

const GuestVerificationWithCertificateAfterVerificationPage: FC<DisplayProps & DataProps> = observer((props) => {
  useInnerPageEnterAnalyticsReport(InnerPage.verifyWithCertificateResult);
  const { t } = useTranslation();
  const { verificationResult } = props;
  const { themeStore } = useInfraStores<ArMode>();

  const renderVerifyResult = (isValidated: boolean, hasAttachments: boolean): ReactElement => {
    if (isValidated) {
      return (
        <StyledVerificationResultCard isValidated resultTitle={t('ar.guestVerification.cert.validatedCertificate')}>
          <Trans i18nKey='ar.guestVerification.cert.validatedCertificateText'>
            The bank account details in this certificate have been verified and match the entry in the nsKnox Global Payee
            Database. Make sure you use these validated banking details when making payments to us.
          </Trans>
          {hasAttachments && (
            <ValidationExtraLineContainer>{t('ar.guestVerification.details.youMayAccess')}</ValidationExtraLineContainer>
          )}
        </StyledVerificationResultCard>
      );
    }

    return (
      <StyledVerificationResultCard isValidated={false} resultTitle={t('ar.guestVerification.cert.notValidatedCertificate')}>
        <Trans i18nKey='ar.guestVerification.cert.notValidatedCertificateText'>
          This certificate has not been validated by nsKnox.
          <br />
          The bank account details in the certificate do not match the validated account details in the nsKnox database.
          <br />
          Some of the details may be wrong or out of date. Ask the payee for a new/updated certificate.
        </Trans>
      </StyledVerificationResultCard>
    );
  };

  const renderPayeeUpdatedWarning = (): ReactElement => {
    return (
      <PayeeUpdatedWarning data-testid='payee-updated-warning'>
        <Trans i18nKey='ar.guestVerification.cert.payeeUpdatedWarning'>
          The payee / account information might have updated since this BAC was created.
          <br />
          In any way, this portal presents the most updated information:
        </Trans>
      </PayeeUpdatedWarning>
    );
  };

  const payeeToDisplay: ValidatedPayeeToDisplay = {
    uniformId: null,
    mainName: verificationResult.payeeData.mainName,
    names: verificationResult.payeeData.otherNames,
    addresses: wrapValueAsArray(verificationResult.payeeData.address),
    countryCode: verificationResult.payeeData.countryCode,
    strongLegalIdentifies: {
      [StrongLegalIdentifiesTypes.KnoxId]: wrapValueAsArray<LegalEntityIdentifier>(verificationResult.payeeData.knoxId),
      [StrongLegalIdentifiesTypes.DUNS]: wrapValueAsArray<LegalEntityIdentifier>(verificationResult.payeeData.duns),
      [StrongLegalIdentifiesTypes.TaxId]: verificationResult.payeeData.taxIds,
    },
    legalIdentifiers: verificationResult.payeeData.regularLegalIds,
    emails: [],
    phones: [],
    faxes: [],
    websites: [],
    primaryFields: null,
  };

  return (
    <PageWithHeader paddingTop={52} width={900} widthReactive>
      <PageContainer>
        {!themeStore.theme.brand?.clientName && <StyledJoinARBanner />}
        <ARAboveMainCardTitle title={t('ar.guestVerification.cert.certificateVerification')} showBackButton />
        <ResultsCard>
          {renderVerifyResult(verificationResult.validated, !!verificationResult.validatedPayeeAccountAttachments.length)}
          {verificationResult.isPayeeUpdated && renderPayeeUpdatedWarning()}
          <DataFromCertificateExplanation>{t('ar.guestVerification.cert.bankAccountCertDetails')}</DataFromCertificateExplanation>
          <PayeeTitle>
            <Regular22TransparentBlack900.div>{payeeToDisplay.mainName}</Regular22TransparentBlack900.div>
            <SanctionsBadge sanctionsScreeningInfo={verificationResult?.sanctionsScreeningInfo} />
          </PayeeTitle>
          <CertificateDetailsGrid>
            <div>
              <CertificateDetailsTitle>{t('ar.guestVerification.payeeDetails')}</CertificateDetailsTitle>
              <VerificationValidatedPayeeDetails payee={payeeToDisplay} hideMainName />
            </div>
            <div>
              <CertificateDetailsTitle>{t('ar.guestVerification.bankAccountDetails')}</CertificateDetailsTitle>
              <GuestAccountDetails
                payeeAccount={verificationResult.accountData}
                validationTimestamp={verificationResult.validationTimestamp}
              />
            </div>
          </CertificateDetailsGrid>
          <AccountAttachments
            validatedAccountAttachments={verificationResult.validatedPayeeAccountAttachments}
            downloadHandler={downloadAccountAttachment}
            source={AttachmentSource.guestVerification}
          />
        </ResultsCard>
      </PageContainer>
    </PageWithHeader>
  );
});

const LoadingGuestVerificationWithCertificateAfterVerificationPage: FC<DisplayProps> = observer((props) => {
  const { t } = useTranslation();
  const { themeStore } = useInfraStores<ArMode>();

  return (
    <PageWithHeader paddingTop={52} width={900} widthReactive>
      <PageContainer>
        {!themeStore.theme.brand?.clientName && <StyledJoinARBanner />}
        <ARAboveMainCardTitle title={t('ar.guestVerification.cert.certificateVerification')} showBackButton />
        <ResultsCard>
          <DecryptingIcon>
            <SVG accessibilityLabel='' image={DecryptingImage} width={61} height={59} />
          </DecryptingIcon>
          <DecryptingText role='status'>{t('ar.guestVerification.cert.decoding')}</DecryptingText>
          <Regular22TransparentBlack900.div>
            <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 230, margin: '0.2em' }} />
          </Regular22TransparentBlack900.div>
          <CertificateDetailsGrid>
            <div>
              <CertificateDetailsTitle>{t('ar.guestVerification.payeeDetails')}</CertificateDetailsTitle>
              <VerificationValidatedPayeeDetails loading hideMainName />
            </div>
            <div>
              <CertificateDetailsTitle>{t('ar.guestVerification.bankAccountDetails')}</CertificateDetailsTitle>
              <GuestAccountDetails loading />
            </div>
          </CertificateDetailsGrid>
        </ResultsCard>
      </PageContainer>
    </PageWithHeader>
  );
});

export default withPlaceholder<DisplayProps, DataProps>(
  GuestVerificationWithCertificateAfterVerificationPage,
  LoadingGuestVerificationWithCertificateAfterVerificationPage,
);

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledJoinARBanner = styled(JoinARBanner)`
  width: 100%;
  margin-bottom: 16px;
`;

const ResultsCard = styled(Card)`
  font-family: var(--text-font-family);

  width: 100%;
  padding: 24px 32px;
  margin-bottom: 36px;
`;

const StyledVerificationResultCard = styled(VerificationResultCard)`
  margin-bottom: 32px;
`;

const DataFromCertificateExplanation = styled(BodySmallStartTransparentBlack600.div)`
  margin-bottom: 16px;
`;

const PayeeUpdatedWarning = styled(Bold13TransparentBlack900.div)`
  margin-bottom: 16px;
`;

const CertificateDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  margin-top: 30px;
`;

const CertificateDetailsTitle = styled(SubtitleSmallStartTransparentBlack600.div)`
  margin-bottom: 18px;
`;

const DecryptingIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
`;

const DecryptingText = styled(Bold13TransparentBlack900.div)`
  text-align: center;
  margin-bottom: 80px;
`;

const ValidationExtraLineContainer = styled.div`
  margin-top: 15px;
`;
