import { extractLogErrorIdFromError, HttpStatus, isWebErrorContent, RequestError } from '@app/libs/request';
import Log from '@app/libs/logger';
import { LegalEntityIdentifier } from '@app/domain/legalEntityIdentifier';
import { PrivatePayeeCompanies } from '@mortee/domain/privatePayee';
import { DeterministicValidationResultType } from '@app/domain/deterministicValidation';
import { VerificationResultType } from '@mortee/domain/morteeAccountVerification';
import { PrivatePayeeAccountValidationStatus } from '@mortee/domain/privatePayeeAccount';
import { ValidatedPayeeAccountValidationStatus } from '@app/domain/validatedPayeeAccount';
import { extractErroredFileName } from '@app/components/fileUpload/fileUploadUtils';
import { FilesErrorOutput, FileTypes, generalFilesErrors } from '@app/domain/files';

export const LOAD_MVF_FILE_TITLE: string = 'Upload new MVF file. The file will be compared with the most recent version.';

export enum MVF_CHECK_ERRORS {
  defaultError = 'DEFAULT_ERROR',
  emptyFile = 'EMPTY_FILE',
  emptyFileName = 'EMPTY_FILE_NAME',
  fileUnauthorizedContentType = 'FILE_UNAUTHORIZED_CONTENT_TYPE',
  wrongNamePattern = 'WRONG_FILE_NAME_PATTERN',
  noSuitableParser = 'MVF_NO_SUITABLE_PARSER',
  fileBadFormat = 'FILE_BAD_FORMAT',
  missingInternalId = 'MVF_MISSING_INTERNAL_ID',
  missingMandatoryFields = 'CONFIGURATION_MISSING_MANDATORY_FIELDS',
  orgDuplicateFields = 'ORG_DUPLICATE_FIELDS',
}

interface ExpectedValue {
  min: number;
  max: number;
}

interface MVFErrorAdditionalData {
  line?: number;
  expectedValue?: number | string | ExpectedValue | string[];
}

export const MVF_ALLOWED_FILE_TYPES: FileTypes[] = [FileTypes.xls, FileTypes.xlsx, FileTypes.csv];

export const MvfCheckErrors: Record<MVF_CHECK_ERRORS, { text: (values: MVFErrorAdditionalData) => string }> = {
  [MVF_CHECK_ERRORS.defaultError]: {
    text: (): string =>
      'An error has occurred. Please try again. If the problem persists, please contact us at support@nsknox.net',
  },
  [MVF_CHECK_ERRORS.emptyFile]: {
    text: (): string => 'Your file does not contain any vendor/data. Please check and try again',
  },
  [MVF_CHECK_ERRORS.emptyFileName]: {
    text: (): string => 'Please enter a valid file name',
  },
  [MVF_CHECK_ERRORS.fileUnauthorizedContentType]: {
    text: (values: MVFErrorAdditionalData): string => {
      const allowed = (values.expectedValue as string[])?.join();
      if (allowed) {
        return `Invalid file type. Please use one of the following permitted file types: ${allowed}`;
      }
      return `Invalid file type`;
    },
  },
  [MVF_CHECK_ERRORS.wrongNamePattern]: {
    text: (): string => 'File name must not contain the following characters: ; , : ? " * | \\ /',
  },
  [MVF_CHECK_ERRORS.noSuitableParser]: {
    text: (): string => 'MVF file format not recognized. Please check and try again',
  },
  [MVF_CHECK_ERRORS.fileBadFormat]: {
    text: (): string => 'File format failure. Please check formatting of your file and resubmit',
  },
  [MVF_CHECK_ERRORS.missingInternalId]: {
    text: (values: MVFErrorAdditionalData): string => {
      const { line } = values;
      if (line) {
        return `MVF file format failure. Missing internal ID in line number ${line}`;
      }
      return `MVF file format failure. Missing internal ID`;
    },
  },
  [MVF_CHECK_ERRORS.missingMandatoryFields]: {
    text: (): string => 'MVF file mapping failure. Please contact us at support@nsknox.net',
  },
  [MVF_CHECK_ERRORS.orgDuplicateFields]: {
    text: (): string => 'MVF file mapping failure. Please contact us at support@nsknox.net',
  },
};

export function handleMvfFileUploadError(error: RequestError): FilesErrorOutput {
  const errorLogId = extractLogErrorIdFromError(error) || null;

  if (error.code !== HttpStatus.badRequest) {
    return { errorLogId, erroredFiles: null };
  }

  const { responseJSON } = error;

  if (!responseJSON) {
    Log.exception(new Error('missing responseJSON'));
    return { errorLogId, erroredFiles: null };
  }

  if (!isWebErrorContent(responseJSON)) {
    Log.exception(new Error('missing responseJSON'));
    return { errorLogId, erroredFiles: null };
  }

  const { error: errorCode, additionalData } = responseJSON;

  if (!errorCode || !additionalData) {
    Log.exception(new Error('missing errorCode or additionalData'));
    return { errorLogId, erroredFiles: null };
  }

  const filesNames: string[] = extractErroredFileName(error);

  if (!filesNames.length) {
    return { errorLogId, erroredFiles: null };
  }

  let mvfCheckErrorMessage = MvfCheckErrors[errorCode]?.text(additionalData);

  if (!mvfCheckErrorMessage) {
    Log.exception(new Error(`missing MvfCheckErrors const for ${errorCode}`), { responseJSON });
    mvfCheckErrorMessage = 'An unknown file error has occurred, please contact us at support@nsknox.net';
  }

  const mvfCheckErrorMessageWithLogId = mvfCheckErrorMessage + (errorLogId ? ` (Error Code: ${errorLogId})` : '');

  const filesAndErrors = Object.fromEntries(
    filesNames.map((fileName): [string, string] => [fileName, mvfCheckErrorMessageWithLogId]),
  );

  return { errorLogId: null, erroredFiles: filesAndErrors };
}

export function getErrorTextByCode(errorCode, additionalData: any): string | undefined {
  if (MvfCheckErrors[errorCode]) {
    return MvfCheckErrors[errorCode]?.text(additionalData);
  }
  if (generalFilesErrors[errorCode]) {
    return generalFilesErrors[errorCode]?.text(additionalData);
  }
}

export enum FileSurveyReportPayeeChangeType {
  newPayee = 'NewPayee',
  changedPayee = 'ChangedPayee',
  removedPayee = 'RemovedPayee',
  unchangedPayee = 'UnchangedPayee',
}

// Enum's order is important it is influencing sorting process
export enum FileSurveyReportAccountChangeType {
  removedAccount = 'RemovedAccount',
  newAccount = 'NewAccount',
}

export const ChangeTypeTranslation = {
  [FileSurveyReportPayeeChangeType.newPayee]: 'New vendor',
  [FileSurveyReportPayeeChangeType.changedPayee]: 'Changed vendor',
  [FileSurveyReportPayeeChangeType.removedPayee]: 'Removed vendor',
  [FileSurveyReportPayeeChangeType.unchangedPayee]: 'New vendor',
  [FileSurveyReportAccountChangeType.newAccount]: 'New Bank Account',
  [FileSurveyReportAccountChangeType.removedAccount]: 'Removed Bank Account',
};

export const changeTypeOrder: (FileSurveyReportPayeeChangeType | FileSurveyReportAccountChangeType)[] = [
  FileSurveyReportPayeeChangeType.newPayee,
  FileSurveyReportPayeeChangeType.changedPayee,
  FileSurveyReportPayeeChangeType.removedPayee,
  FileSurveyReportPayeeChangeType.unchangedPayee,
  FileSurveyReportAccountChangeType.newAccount,
  FileSurveyReportAccountChangeType.removedAccount,
];

export enum MVFCheckWarningType {
  missingLEIValue = 'MissingLEIValue',
  missingSupplierName = 'MissingSupplierName',
  missingAccountDetails = 'MissingAccountDetails',
  invalidIBAN = 'InvalidIBAN',
  multipleIBAN = 'MultipleIBAN',
  invalidSwift = 'InvalidSwift',
  multipleSwift = 'MultipleSwift',
  invalidDuns = 'InvalidDUNS',
}

export const changelogWarningTranslation = (warning: MVFCheckWarning): string => {
  const { warningType, value } = warning;

  switch (warningType) {
    case MVFCheckWarningType.invalidIBAN:
      return `Invalid IBAN format (${value})`;
    case MVFCheckWarningType.multipleIBAN:
      return `Multiple IBAN values (${value})`;
    case MVFCheckWarningType.invalidSwift:
      return `Invalid SWIFT format (${value})`;
    case MVFCheckWarningType.multipleSwift:
      return `Multiple SWIFT values (${value})`;
    case MVFCheckWarningType.missingAccountDetails:
      return 'Missing account details';
    case MVFCheckWarningType.invalidDuns:
      return `Invalid DUNS format (${value})`;
    case MVFCheckWarningType.missingLEIValue:
      return 'Missing LEI';
    case MVFCheckWarningType.missingSupplierName:
      return 'Missing Payee name';
  }
};

export interface MVFCheckWarning {
  value: string;
  warningType: MVFCheckWarningType;
}

export interface FileSurveyReportPayeeDataWithWarnings extends FileSurveyReportPayeeData {
  warnings: MVFCheckWarning[];
}

export interface FileSurveyReportPayeeData {
  addresses: string[];
  countryCode: string;
  description: string;
  emails: string[];
  faxes: string[];
  legalIdentifiers: LegalEntityIdentifier[];
  names: string[];
  phones: string[];
  websites: string[];
  supplierNumbers: string[];
  companies: PrivatePayeeCompanies[];
}

export interface FileSurveyReportAccountDeterministicValidation {
  privatePayeeNames: string[];
  privateLegalEntityIdentifiers: LegalEntityIdentifier[];
  privateAccountValidations: PrivatePayeeDeterministicValidationData[];
  archivedValidatedPayees: ArchivedValidatedPayee[] | null;
  validationResult: DeterministicValidationResultType;
}

export interface PrivatePayeeDeterministicValidationData {
  accountDetails: MorteeAccountDetails;
  validationStatus: PrivatePayeeAccountValidationStatus;
}

export interface FileSurveyReportAccountNonDeterministicVerification {
  privatePayeeNames: string[];
  privateLegalEntityIdentifiers: LegalEntityIdentifier[];
  privateAccountDetails: MorteeAccountDetailsExtended | null;
  validatedAccountDetails: MorteeAccountDetailsExtended | null;
  validatedNames: string[] | null;
  validatedAddresses: string[] | null;
  primaryValidatedName: string;
  validatedCountryCode: string | null;
  validatedLegalEntityIdentifiers: LegalEntityIdentifier[];
  primaryValidatedLegalEntityIdentifier: LegalEntityIdentifier;
  verificationResult: VerificationResultType;
}

export interface ArchivedValidatedPayee {
  validatedPayeeAccounts: ArchivedValidatedPayeeAccount[];
  validatedLegalEntityIdentifiers: LegalEntityIdentifier[];
  primaryValidatedLegalEntityIdentifier: LegalEntityIdentifier;
  validatedNames: string[] | null;
  primaryValidatedName: string;
  validatedAddresses: string[] | null;
  validatedCountryCode: string | null;
}

interface ArchivedValidatedPayeeAccount {
  accountDetails: MorteeAccountDetailsExtended;
  validationStatus: ValidatedPayeeAccountValidationStatus;
}

export interface FileSurveyReportAccount {
  changeType: FileSurveyReportAccountChangeType;
  accountDetails: MorteeAccountDetailsExtended;
  warnings: MVFCheckWarning[];
  nonDeterministicVerifications: FileSurveyReportAccountNonDeterministicVerification[] | null;
  deterministicValidation: FileSurveyReportAccountDeterministicValidation | null;
}

export interface FileSurveyReport {
  externalReferenceId: string;
  changeType: FileSurveyReportPayeeChangeType;
  data: FileSurveyReportPayeeDataWithWarnings;
  oldData: FileSurveyReportPayeeData | null;
  changedFields: (keyof FileSurveyReportPayeeData)[] | null;
  accounts: FileSurveyReportAccount[];
}

export interface StoredMvfFileSurveyServerResponse {
  id: string;
  organizationId: string;
  writeTimestamp: number;
  data: FileSurveyCreatedEventDataServerResponse;
  performingUserName: string;
  status: FileSurveyStatus;
}

export interface FileSurveyCreatedEventDataServerResponse {
  note: string;
  vendorFilesMetadata: FileMetadata[];
}

export enum FileSurveyStatus {
  fileSurveySyncInProgress = 'FileSurveySyncInProgress',
  fileSurveyVerificationInProgress = 'FileSurveyVerificationInProgress',
  fileSurveyComplete = 'FileSurveyComplete',
  fileSurveyFailed = 'FileSurveyFailed',
}
