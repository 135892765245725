import { DeterministicValidationResultType } from '@app/domain/deterministicValidation';
import { LegalEntityIdentifier, LegalEntityIdentifierServerResponse } from '@app/domain/legalEntityIdentifier';
import { WebErrorCode } from '@app/libs/request/httpConsts';
import { ValidatedPayeeAccountAttachment } from '@app/domain/validatedPayeeAccount';
import { SanctionsScreeningInfo, SanctionsScreeningInfoServerResponse } from '@app/domain/validatedPayee';

export interface BankAccountCertificatePayeeDataServerResponse {
  mainName: string;
  otherNames: string[];
  address: string | null;
  countryCode: string;
  knoxId: LegalEntityIdentifierServerResponse | null;
  duns: LegalEntityIdentifierServerResponse | null;
  taxIds: LegalEntityIdentifierServerResponse[];
  regularLegalIds: LegalEntityIdentifierServerResponse[];
}

export interface BankAccountCertificateAccountDataServerResponse {
  accountDetails: MorteeAccountDetails;
  bankCountryCode: string | undefined;
  bankName: string | undefined;
  furtherCredit: string | undefined;
}

export interface BankAccountCertificatePayeeData {
  mainName: string;
  otherNames: string[];
  address: string | null;
  countryCode: string;
  knoxId: LegalEntityIdentifier | null;
  duns: LegalEntityIdentifier | null;
  taxIds: LegalEntityIdentifier[];
  regularLegalIds: LegalEntityIdentifier[];
}

export interface BankAccountCertificateAccountData {
  accountDetails: MorteeAccountDetails;
  bankCountryCode: string | undefined;
  bankName: string | undefined;
  furtherCredit: string | undefined;
}

export interface CertificateVerificationError {
  errorType: WebErrorCode;
  errorLogId?: string;
}

export const CertificateVerificationErrorsTypes: WebErrorCode[] = [
  'BANK_ACCOUNT_CERTIFICATE_NOT_VALID_FILE_FORMAT',
  'BANK_ACCOUNT_CERTIFICATE_PDF_SIGNATURE_ERROR',
  'BANK_ACCOUNT_CERTIFICATE_PDF_HASH_COMPARISON_ERROR',
  'BANK_ACCOUNT_CERTIFICATE_BAC_SIGNATURE_ERROR',
  'BANK_ACCOUNT_CERTIFICATE_BAC_EXPIRED',
  'BANK_ACCOUNT_CERTIFICATE_GENERAL_ERROR',
];

export interface AccountVerificationBankAccountCertificateVerifyResultServerResponse {
  payeeData: BankAccountCertificatePayeeDataServerResponse;
  accountData: BankAccountCertificateAccountDataServerResponse;
  validationResult: DeterministicValidationResultType;
  validatedPayeeAccountAttachments: ValidatedPayeeAccountAttachment[] | undefined;
  lastValidationTimestamp: number;
  lastSanctionsScreeningEvent: SanctionsScreeningInfoServerResponse | null;
  isPayeeUpdated: boolean;
}

export interface AccountVerificationBankAccountCertificateVerifyResult {
  payeeData: BankAccountCertificatePayeeData;
  accountData: BankAccountCertificateAccountData;
  validationResult: DeterministicValidationResultType;
  validatedPayeeAccountAttachments: ValidatedPayeeAccountAttachment[];
  validationTimestamp: number;
  sanctionsScreeningInfo: SanctionsScreeningInfo | null;
  isPayeeUpdated: boolean;
}

export function transformAccountVerificationBankAccountCertificateVerifyResult(
  serverResponse: AccountVerificationBankAccountCertificateVerifyResultServerResponse,
): AccountVerificationBankAccountCertificateVerifyResult {
  return {
    ...serverResponse,
    validatedPayeeAccountAttachments: serverResponse.validatedPayeeAccountAttachments ?? [],
    validationTimestamp: serverResponse.lastValidationTimestamp,
    sanctionsScreeningInfo: serverResponse.lastSanctionsScreeningEvent,
  };
}
