import React, { FC, ReactElement, ReactNode, useEffect } from 'react';
import styled from '@emotion/styled';
import { observer, useLocalObservable } from 'mobx-react';
import {
  REGISTRATION_TYPES_VALUES,
  RegistrationType,
  registrationTypeFromBoolean,
  SupplierRegistrationCompanyInfoFormFields,
  SupplierRegistrationFormValuesState,
  SupplierValidationRegistrationProps,
} from '@supplierRegistration/domain/supplierRegistration';
import { Trans, useTranslation } from 'react-i18next';
import {
  CardSubTitle,
  CardTitle,
  MainCard,
  SeparatorDiv,
  SmallContinueButton,
  SmallSeparator,
  SmallSeparatorWithExtraMargin,
  StyledForm,
} from '../../Styles';
import { FormComponentProps, WrappedFormUtils } from 'antd/es/form/Form';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import { Form } from 'antd';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { FIELD_MAX_LENGTH } from '@supplierRegistration/domain/supplierRegistrationFields';
import { FormFieldDecorators } from '@app/utils/form/form';
import { trim } from '@app/utils/stringUtils';
import { VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import COUNTRIES, { SUPPLIER_REGISTRATION_COUNTRY_CODE_BLACKLIST, COUNTRY_CODES, getCountriesOrder } from '@app/domain/countries';
import USA_STATES, { getUsaStatesOrder } from '@app/domain/states';
import { emailAdditionalValidations, onChangeValidateOtherFields, validatePhoneNumber } from '@app/utils/validators';
import { convertToTranslatedMessage } from '@app/utils/form/formTranslatedMessage';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import DropdownItem from '@app/components/inputs/DropdownItem';
import { LocaleAwareDiv, LocaleAwareDropdownItem } from '@app/components/LocaleAwareComponents';
import {
  AllLegalIdTypeForCompany,
  getAdditionalLeiValueRules,
  getAdditionalLeiValueRulesForCountryForLeiType,
  getInitialLegalIdTypValuesOfCountryForCompany,
  getLegalIdTypesOfCountryForCompany,
} from '@supplierRegistration/domain/supplierRegistrationLegalIdentifiers';
import * as countryProperties from '@supplierRegistration/domain/countries';
import i18n from 'i18next';
import InputDecorator from '@app/components/inputs/InputDecorator';
import { isDefined } from '@app/utils/utils';
import useInfraStores from '@app/hooks/useInfraStores';
import { storeUserCountry, useLocaleCountry } from '@app/hooks/useLocaleCountry';
import useForm from '@app/hooks/useForm';
import ReferringOrg from '@supplierRegistration/components/ReferringOrg';
import PleaseNote from '@supplierRegistration/components/PleaseNote';
import FormRadioGroup from '@app/components/inputs/FormRadioGroup';
import useMountEffect from '@app/hooks/useMountEffect';
import useTheme from '@app/hooks/useTheme';
import DatePicker, {
  DatePickerFormat,
  datePickerValueFullDateRepresentationValidator,
  datePickerValueNotFutureTimestampValidator,
} from '@app/components/inputs/DatePicker';
import useAppStores from '@app/hooks/useAppStores';
import { AsyncInitiateValidationMaskedData } from '@supplierRegistration/domain/supplierRegistrationAsyncInitiateValidationProcess';
import SupplierRegistrationFormItemsBox from '@supplierRegistration/components/SupplierRegistrationFormItemsBox';
import SupplierRegistrationFormItemBox from '@supplierRegistration/components/SupplierRegistrationFormItemBox';
import useLeaveConfirmation from '@app/hooks/useLeaveConfirmation';
import CountryDropdown from '@supplierRegistration/components/CountryDropdown';

const monthsTranslations: string[] = [
  'general.months.january',
  'general.months.february',
  'general.months.march',
  'general.months.april',
  'general.months.may',
  'general.months.june',
  'general.months.july',
  'general.months.august',
  'general.months.september',
  'general.months.october',
  'general.months.november',
  'general.months.december',
];

interface Props extends FormComponentProps<SupplierRegistrationCompanyInfoFormFields>, SupplierValidationRegistrationProps {}

const SupplierRegistrationCompanyInformation: FC<Props> = observer((props) => {
  useLeaveConfirmation();

  const { configuration, formValues, onFormSubmit, isIpsMode, nextStep, saveValues } = props;
  const { form, showFormErrors, setShowFormErrors } = useForm(props);
  const { t } = useTranslation();
  const { languageStore } = useInfraStores<SupplierRegistrationMode>();
  const { supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();
  const { brand, continueButtonColor, fontFamily } = useTheme<SupplierRegistrationMode>();

  const customerName = supplierRegistrationCustomerDataStore.customerName;
  const supplierCountryCode = form.getFieldValue('country');
  const addressCountryCode = form.getFieldValue('companyAddressCountry');
  const leiType = form.getFieldValue('leiType');
  const legalIdTypesOfCountry = getLegalIdTypesOfCountryForCompany(supplierCountryCode);
  const isAsync = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData?.isResolved();

  useLocaleCountry(
    (userCountry, userCountryCode): false | number =>
      !formValues.supplierValues?.phoneCountryCode &&
      window.setTimeout((): void => form.setFieldsValue({ phoneCountryCode: userCountryCode }), 0),
  );

  const onLeiTypeChange = (leiTypeKey: string): void => {
    localStore.setIsOtherLEIType(AllLegalIdTypeForCompany[leiTypeKey] === AllLegalIdTypeForCompany.Other);
    form.validateFields();
  };

  const isIndividual = (): boolean => {
    return form.getFieldValue('registrationType') === RegistrationType.individual;
  };

  const showDateOfBirth = (): boolean => {
    return isIndividual() && !!supplierCountryCode && countryProperties?.[supplierCountryCode]?.showDateOfBirth;
  };

  const showAdditionalPersonalName = (): boolean => {
    return isIndividual() && !!supplierCountryCode && countryProperties?.[supplierCountryCode]?.additionalPersonalName;
  };

  const showAdditionalCompanyName = (): boolean => {
    return !isIndividual() && !!supplierCountryCode && countryProperties?.[supplierCountryCode]?.additionalCompanyName;
  };

  const doesSupplierCountryHasAdditionalName = showAdditionalPersonalName() || showAdditionalCompanyName();

  const asyncProcessMaskedData = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData?.result;

  useEffect((): void => {
    form.resetFields(['leiType']);
    localStore.setIsOtherLEIType(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [legalIdTypesOfCountry]);

  useEffect((): void => {
    if (doesSupplierCountryHasAdditionalName) {
      form.resetFields(['additionalCompanyName']);
    }
    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [doesSupplierCountryHasAdditionalName]);

  useMountEffect((): VoidFunction => (): void => saveValues?.(form.getFieldsValue()));

  const localStore = useLocalObservable(() => ({
    isOtherLEIType: false as boolean,

    setIsOtherLEIType: (isOtherLEIType: boolean): void => {
      localStore.isOtherLEIType = isOtherLEIType;
    },
  }));

  const handleRegistrationTypeChange = (): void => {
    form.resetFields(Object.keys(fieldDecorators).filter((field) => field !== 'registrationType'));
  };

  const handleOk = (): void => {
    const { validateFieldsAndScroll } = form;
    setShowFormErrors('all');

    validateFieldsAndScroll((errors: Object, values: SupplierRegistrationCompanyInfoFormFields) => {
      if (errors) {
        return;
      }

      onFormSubmit(values);
      storeUserCountry(values.phoneCountryCode);
      nextStep();
    });
  };

  const onSupplierCountryChange = (countryCode: string | undefined): void => {
    form.setFieldsValue({
      phoneCountryCode: form.getFieldValue('phoneNumber') ? form.getFieldValue('phoneCountryCode') : countryCode,
    });
    form.setFieldsValue({ companyAddressCountry: form.getFieldValue('companyAddressCountry') ?? countryCode });
  };

  const renderPhoneCountryDropdown = (): ReactElement => {
    return (
      <NakedDropdown
        accessibilityLabel={t('general.accessibility.phoneCountry')}
        name='drp-supp-reg-phone-country-name'
        dataTestId='drp-supp-reg-phone-country-name'
        placeholder={null}
        isSearchable
        popupWidth='auto'
      >
        {getCountriesOrder(i18n.language, (name) => t(`supplierValidation.countries.${name}`)).map((countryCode) => {
          const country = COUNTRIES[countryCode];
          return (
            <PhoneCountryDropdownItem
              key={countryCode}
              keywords={[t(`supplierValidation.countries.${country.name}`), `+${country.dialCode}`, countryCode]}
              value={countryCode}
              textWhenSelected={`+${country.dialCode}`}
            >
              <PhoneCountryDisplay>
                <div>{t(`supplierValidation.countries.${country.name}`)}</div>
                <StyledCountryDialCode>+{country.dialCode}</StyledCountryDialCode>
              </PhoneCountryDisplay>
            </PhoneCountryDropdownItem>
          );
        })}
      </NakedDropdown>
    );
  };

  const renderStateDropdown = (name: string, placeholder: string): ReactElement => {
    return (
      <NakedDropdown
        accessibilityLabel={t('general.accessibility.state')}
        name={name}
        dataTestId={name}
        placeholder={t<string>(placeholder)}
        isSearchable
      >
        {getUsaStatesOrder(i18n.language, (name) => t(`supplierValidation.states.${name}`)).map((state) => {
          const stateValue = USA_STATES[state];
          return (
            <CountryDropdownItem
              key={state}
              keywords={[t(`supplierValidation.states.${state}`), state]}
              value={stateValue}
              textWhenSelected={`supplierValidation.states.${state}`}
            >
              <div>{t(`supplierValidation.states.${state}`)}</div>
            </CountryDropdownItem>
          );
        })}
      </NakedDropdown>
    );
  };

  const renderLEITypeFormItem = (
    form: WrappedFormUtils<SupplierRegistrationCompanyInfoFormFields>,
    fieldDecorators: FormFieldDecorators<SupplierRegistrationCompanyInfoFormFields>,
    isIndividual: boolean,
  ): ReactNode => {
    if (isIndividual && !asyncProcessMaskedData?.payee.legalIdentifier) {
      return null;
    }

    const otherLEIType = localStore.isOtherLEIType || isDefined(asyncProcessMaskedData?.payee.legalIdentifier);

    return (
      <SupplierRegistrationFormItemsBox
        form={form}
        fieldNames={otherLEIType ? ['leiType', 'leiTypeOtherValue'] : ['leiType']}
        showErrors={showFormErrors}
        hasPrefilledData={isDefined(asyncProcessMaskedData?.payee.legalIdentifier)}
      >
        <MultiInputsContainer areChildrenRTL={languageStore.isRTL}>
          <FirstInputContainer>
            <InputDecorator form={form} fieldName='leiType' fieldDecoratorOptions={fieldDecorators.leiType}>
              <NakedDropdown
                accessibilityLabel={t<string>('general.accessibility.legalId')}
                name='drp-supp-reg-lei-type'
                dataTestId='drp-supp-reg-lei-type'
                placeholder={otherLEIType ? null : t<string>('supplierValidation.supplierRegister.legalIdType')}
                onChange={onLeiTypeChange}
                popupWidth={otherLEIType ? 'auto' : undefined}
              >
                {Object.entries(legalIdTypesOfCountry).map(([key, legalIdTypeName]) => {
                  return (
                    <LEITypeDropdownItem
                      key={key}
                      value={legalIdTypeName}
                      textWhenSelected={`supplierValidation.legalIdType.${key}`}
                    >
                      {t(`supplierValidation.legalIdType.${key}`)}
                    </LEITypeDropdownItem>
                  );
                })}
              </NakedDropdown>
            </InputDecorator>
          </FirstInputContainer>
          {otherLEIType && (
            <>
              <InputBuffer />
              <SecondInputContainer>
                <InputDecorator
                  fieldName='leiTypeOtherValue'
                  fieldDecoratorOptions={fieldDecorators.leiTypeOtherValue}
                  form={form}
                >
                  <NakedFormInput
                    name='btn-supp-reg-lei-type-other'
                    dataTestId='btn-supp-reg-lei-type-other'
                    type='text'
                    placeholder={t<string>('supplierValidation.supplierRegister.legalIdType')}
                    disableSuggestion
                  />
                </InputDecorator>
              </SecondInputContainer>
            </>
          )}
        </MultiInputsContainer>
      </SupplierRegistrationFormItemsBox>
    );
  };

  const fieldDecorators = createFieldDecorators(
    form,
    asyncProcessMaskedData,
    isIndividual(),
    supplierCountryCode,
    addressCountryCode,
    formValues,
    showDateOfBirth(),
    leiType,
  );

  function renderPhoneNumber(): ReactElement {
    const hasAsyncMaskedDataPhoneNumber = !!asyncProcessMaskedData?.contactInfo.phoneNumber;

    return (
      <MultiInputsContainer isLTRFixed>
        {hasAsyncMaskedDataPhoneNumber || (
          <FixedInputContainer>
            <SupplierRegistrationFormItemBox
              fieldName='phoneCountryCode'
              fieldDecoratorOptions={fieldDecorators.phoneCountryCode}
              hasPrefilledData={false}
            >
              {renderPhoneCountryDropdown()}
            </SupplierRegistrationFormItemBox>
          </FixedInputContainer>
        )}
        <SecondInputContainer>
          <LocaleAwareDiv includeChildren>
            <SupplierRegistrationFormItemBox
              fieldName='phoneNumber'
              fieldDecoratorOptions={fieldDecorators.phoneNumber}
              hasPrefilledData={hasAsyncMaskedDataPhoneNumber}
            >
              <NakedFormInput
                name='inpt-supp-reg-phone-number'
                dataTestId='inpt-supp-reg-phone-number'
                type='text'
                placeholder={t<string>('supplierValidation.supplierRegister.phone')}
                disableSuggestion
              />
            </SupplierRegistrationFormItemBox>
          </LocaleAwareDiv>
        </SecondInputContainer>
      </MultiInputsContainer>
    );
  }

  function getCardSubTitle(): ReactNode {
    if (isIpsMode) {
      return <>{t('supplierValidation.registerSteps.companyInformation.subTitleIps')}</>;
    }

    if (isAsync) {
      return (
        <Trans i18nKey='supplierValidation.registerSteps.companyInformation.subTitleAsync' values={{ customerName }}>
          Complete missing company details. Details already provided to {{ customerName }} are prefilled. To modify any of these,
          please contact {{ customerName }}.
        </Trans>
      );
    }

    return (
      <Trans i18nKey='supplierValidation.registerSteps.companyInformation.subTitle'>
        Enter your company details that you have <strong>provided to the payer</strong>
      </Trans>
    );
  }

  const isAddressCountryUS = form.getFieldValue('companyAddressCountry') === COUNTRY_CODES.UnitedStates;

  return (
    <>
      <MainCard fontFamily={fontFamily}>
        <StyledForm onSubmit={handleOk} showErrors={showFormErrors} form={form} setShowErrors={setShowFormErrors}>
          {configuration?.clientName && !brand && <ReferringOrg orgName={configuration.clientName} />}
          <CardTitle>
            {isAsync
              ? t('supplierValidation.registerSteps.companyInformation.titleAsync')
              : t('supplierValidation.registerSteps.companyInformation.title')}
          </CardTitle>
          <CardSubTitle>{getCardSubTitle()}</CardSubTitle>
          <SupplierRegistrationFormItemBox
            appearance='none'
            fieldName='registrationType'
            fieldDecoratorOptions={fieldDecorators.registrationType}
            hasPrefilledData={isDefined(asyncProcessMaskedData?.payee.isIndividual)}
            onChange={handleRegistrationTypeChange}
          >
            <StyledFormRadioGroup
              accessibilityLabel={t('supplierValidation.accessibility.registrationType')}
              id='radio-group-supp-reg-registration-type'
              dataTestId='radio-group-supp-reg-registration-type'
              options={[
                {
                  value: RegistrationType.company,
                  label: <RadioOptionLabel>{t('supplierValidation.supplierRegister.company')}</RadioOptionLabel>,
                },
                {
                  value: RegistrationType.individual,
                  label: <RadioOptionLabel>{t('supplierValidation.supplierRegister.individual')}</RadioOptionLabel>,
                },
              ]}
              direction='column'
            />
          </SupplierRegistrationFormItemBox>
          <SmallSeparatorWithExtraMargin />
          <InputGrid>
            <SupplierRegistrationFormItemBox
              fieldName='country'
              fieldDecoratorOptions={fieldDecorators.country}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.payee.countryCode)}
            >
              <CountryDropdown
                name='drp-supp-reg-supplier-country'
                placeholder={t<string>(
                  isIndividual()
                    ? 'supplierValidation.supplierRegister.country'
                    : 'supplierValidation.supplierRegister.companyCountry',
                )}
                excludedCountries={SUPPLIER_REGISTRATION_COUNTRY_CODE_BLACKLIST}
                onChange={onSupplierCountryChange}
              />
            </SupplierRegistrationFormItemBox>
          </InputGrid>
          {showDateOfBirth() && (
            <>
              <SeparatorDiv>
                <SmallSeparator />
                {t(`supplierValidation.supplierRegister.dateOfBirthHeader`)}
              </SeparatorDiv>
              <InputGrid>
                <DateOfBirth>
                  <SupplierRegistrationFormItemBox
                    fieldName='dateOfBirth'
                    fieldDecoratorOptions={fieldDecorators.dateOfBirth}
                    appearance='none'
                    hasPrefilledData={false}
                  >
                    <DatePicker
                      appearance='corners'
                      accessibilityLabel={t(`supplierValidation.supplierRegister.dateOfBirthHeader`)}
                      id='date-picker-birth-of-date'
                      placeholderStyle='onlyWhenEmpty'
                      heightType='thin'
                      dateFormat={DatePickerFormat.YYMMDD}
                      bottomYearLimit={150}
                      translatedMonths={monthsTranslations}
                    />
                  </SupplierRegistrationFormItemBox>
                </DateOfBirth>
              </InputGrid>
            </>
          )}
          <SeparatorDiv>
            <SmallSeparator />
            {t(
              isIndividual()
                ? 'supplierValidation.supplierRegister.individualInfoHeader'
                : 'supplierValidation.supplierRegister.companyInfoHeader',
            )}
          </SeparatorDiv>
          <InputGrid>
            <SupplierRegistrationFormItemBox
              fieldName='companyName'
              fieldDecoratorOptions={fieldDecorators.companyName}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.payee.name)}
            >
              <NakedFormInput
                name='btn-supp-reg-company-name'
                dataTestId='btn-supp-reg-company-name'
                type='text'
                placeholder={t<string>(
                  isIndividual()
                    ? [
                        `supplierValidation.supplierRegister.${supplierCountryCode}.accountOwnerName`,
                        'supplierValidation.supplierRegister.accountOwnerName',
                      ]
                    : [
                        `supplierValidation.supplierRegister.${supplierCountryCode}.compName`,
                        'supplierValidation.supplierRegister.compName',
                      ],
                )}
                disableSuggestion
              />
            </SupplierRegistrationFormItemBox>
            {(doesSupplierCountryHasAdditionalName || showAdditionalPersonalName()) && (
              <SupplierRegistrationFormItemBox
                fieldName='additionalCompanyName'
                fieldDecoratorOptions={fieldDecorators.additionalCompanyName}
                hasPrefilledData={false}
              >
                <NakedFormInput
                  name='btn-supp-reg-additional-company-name'
                  dataTestId='btn-supp-reg-additional-company-name'
                  type='text'
                  placeholder={t<string>(
                    isIndividual()
                      ? [
                          `supplierValidation.supplierRegister.${supplierCountryCode}.additionalPersonalName`,
                          'supplierValidation.supplierRegister.additionalPersonalName',
                        ]
                      : [
                          `supplierValidation.supplierRegister.${supplierCountryCode}.additionalCompanyName`,
                          'supplierValidation.supplierRegister.additionalCompanyName',
                        ],
                  )}
                  disableSuggestion
                />
              </SupplierRegistrationFormItemBox>
            )}
            {renderLEITypeFormItem(form, fieldDecorators, isIndividual())}
            <SupplierRegistrationFormItemBox
              fieldName='leiValue'
              fieldDecoratorOptions={fieldDecorators.leiValue}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.payee.legalIdentifier)}
            >
              <NakedFormInput
                name='btn-supp-reg-lei-value'
                dataTestId='btn-supp-reg-lei-value'
                type='text'
                placeholder={
                  isIndividual()
                    ? t<string>([
                        `supplierValidation.supplierRegister.individualForm.${supplierCountryCode}.legalId`,
                        'supplierValidation.supplierRegister.legalId',
                      ])
                    : t<string>('supplierValidation.supplierRegister.legalId')
                }
                disableSuggestion
              />
            </SupplierRegistrationFormItemBox>
            {!configuration?.ref && !isIpsMode && (
              <SupplierRegistrationFormItemBox
                fieldName='referrer'
                fieldDecoratorOptions={fieldDecorators.referrer}
                hasPrefilledData={false}
              >
                <NakedFormInput
                  name='btn-supp-reg-referrer'
                  dataTestId='btn-supp-reg-referrer'
                  type='text'
                  placeholder={t<string>('supplierValidation.supplierRegister.referrer')}
                  disableSuggestion
                />
              </SupplierRegistrationFormItemBox>
            )}
            <SupplierRegistrationFormItemBox
              fieldName='website'
              fieldDecoratorOptions={fieldDecorators.website}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.payee.website)}
            >
              <NakedFormInput
                name='btn-supp-reg-website'
                dataTestId='btn-supp-reg-website'
                type='text'
                placeholder={t<string>('supplierValidation.supplierRegister.website')}
                disableSuggestion
              />
            </SupplierRegistrationFormItemBox>
          </InputGrid>
          <SeparatorDiv>
            <SmallSeparator />
            {t(`supplierValidation.supplierRegister.addressHeader`)}
          </SeparatorDiv>
          <InputGrid>
            {!isDefined(asyncProcessMaskedData?.payee.address) ? (
              <>
                <SupplierRegistrationFormItemBox
                  fieldName='companyAddressCountry'
                  fieldDecoratorOptions={fieldDecorators.companyAddressCountry}
                  hasPrefilledData={false}
                >
                  <CountryDropdown
                    name='drp-supp-reg-company-address-country'
                    placeholder={t<string>('supplierValidation.supplierRegister.addressCountry')}
                    excludedCountries={SUPPLIER_REGISTRATION_COUNTRY_CODE_BLACKLIST}
                  />
                </SupplierRegistrationFormItemBox>
                <SupplierRegistrationFormItemBox
                  fieldName='companyAddress'
                  fieldDecoratorOptions={fieldDecorators.companyAddress}
                  hasPrefilledData={false}
                >
                  <NakedFormInput
                    name='btn-supp-reg-company-address'
                    dataTestId='btn-supp-reg-company-address'
                    type='text'
                    placeholder={t<string>(
                      isIndividual()
                        ? 'supplierValidation.supplierRegister.address'
                        : [
                            `supplierValidation.supplierRegister.${supplierCountryCode}.companyAddress`,
                            'supplierValidation.supplierRegister.companyAddress',
                          ],
                    )}
                    disableSuggestion
                  />
                </SupplierRegistrationFormItemBox>
                <SupplierRegistrationFormItemBox
                  fieldName='companyAddressCity'
                  fieldDecoratorOptions={fieldDecorators.companyAddressCity}
                  hasPrefilledData={false}
                >
                  <NakedFormInput
                    name='btn-supp-reg-company-address-city'
                    dataTestId='btn-supp-reg-company-address-city'
                    type='text'
                    placeholder={t<string>('supplierValidation.supplierRegister.addressCity')}
                    disableSuggestion
                  />
                </SupplierRegistrationFormItemBox>
                <TransparentSupplierRegistrationFormItemBox
                  shouldBeHidden={!isAddressCountryUS}
                  fieldName='companyAddressState'
                  fieldDecoratorOptions={fieldDecorators.companyAddressState}
                  hasPrefilledData={false}
                >
                  {renderStateDropdown(
                    'drp-supp-reg-company-address-state',
                    t<string>('supplierValidation.supplierRegister.addressState'),
                  )}
                </TransparentSupplierRegistrationFormItemBox>
                <SupplierRegistrationFormItemBox
                  fieldName='companyAddressZipCode'
                  fieldDecoratorOptions={fieldDecorators.companyAddressZipCode}
                  hasPrefilledData={false}
                >
                  <NakedFormInput
                    name='btn-supp-reg-company-address-zipcode'
                    dataTestId='btn-supp-reg-company-address-zipcode'
                    type='text'
                    placeholder={t<string>('supplierValidation.supplierRegister.addressZipCode')}
                    disableSuggestion
                  />
                </SupplierRegistrationFormItemBox>
              </>
            ) : (
              <SupplierRegistrationFormItemBox
                fieldName='companyAddress'
                fieldDecoratorOptions={fieldDecorators.companyAddress}
                hasPrefilledData={true}
              >
                <NakedFormInput
                  name='btn-supp-reg-company-address'
                  dataTestId='btn-supp-reg-company-address'
                  type='text'
                  placeholder={t<string>(
                    isIndividual()
                      ? 'supplierValidation.supplierRegister.address'
                      : [
                          `supplierValidation.supplierRegister.${supplierCountryCode}.companyAddress`,
                          'supplierValidation.supplierRegister.companyAddress',
                        ],
                  )}
                  disableSuggestion
                />
              </SupplierRegistrationFormItemBox>
            )}
          </InputGrid>
          <SeparatorDiv>
            <SmallSeparator />
            {t(`supplierValidation.supplierRegister.contactInfoHeader`)}
          </SeparatorDiv>
          <InputGrid>
            <SupplierRegistrationFormItemBox
              fieldName='fullName'
              fieldDecoratorOptions={fieldDecorators.fullName}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.contactInfo.fullName)}
            >
              <NakedFormInput
                name='btn-supp-reg-full-name'
                dataTestId='btn-supp-reg-full-name'
                type='text'
                placeholder={t<string>('supplierValidation.supplierRegister.contactName')}
                disableSuggestion
              />
            </SupplierRegistrationFormItemBox>
            {renderPhoneNumber()}
            <SupplierRegistrationFormItemBox
              fieldName='email'
              fieldDecoratorOptions={fieldDecorators.email}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.contactInfo.email)}
            >
              <NakedFormInput
                name='inpt-auth-forgot-enter-email'
                dataTestId='inpt-auth-forgot-enter-email'
                type='text'
                placeholder={t<string>('supplierValidation.supplierRegister.mail')}
                disableSuggestion
              />
            </SupplierRegistrationFormItemBox>
          </InputGrid>
          <SmallContinueButton id='btn-supp-reg-company-info-continue' onClick={handleOk} colorScheme={continueButtonColor}>
            {t('supplierValidation.supplierRegister.continue')}
          </SmallContinueButton>
        </StyledForm>
      </MainCard>
      <PleaseNote isIpsMode={isIpsMode} configuration={configuration} />
    </>
  );
});

export default Form.create<Props>()(SupplierRegistrationCompanyInformation);

const createFieldDecorators = (
  form: WrappedFormUtils,
  asyncProcessMaskedData: AsyncInitiateValidationMaskedData | undefined,
  isIndividual: boolean,
  supplierCountryCode: string | undefined,
  addressCountryCode: string | undefined,
  formInitialValues: SupplierRegistrationFormValuesState,
  showDateOfBirth: boolean | undefined,
  leiType: string | undefined,
): FormFieldDecorators<SupplierRegistrationCompanyInfoFormFields> => ({
  dateOfBirth: {
    initialValue: formInitialValues.supplierValues?.dateOfBirth ?? undefined,
    rules: [
      {
        required: showDateOfBirth,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingDateOfBirth',
      },
      datePickerValueNotFutureTimestampValidator(),
      datePickerValueFullDateRepresentationValidator(),
    ],
  },
  companyName: {
    initialValue: asyncProcessMaskedData?.payee.name ?? formInitialValues.supplierValues?.companyName ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedData?.payee.name),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: isIndividual
          ? [
              `supplierValidation.supplierRegister.${supplierCountryCode}.errors.missingOwnerName`,
              'supplierValidation.supplierRegister.errors.missingOwnerName',
            ]
          : [
              `supplierValidation.supplierRegister.${supplierCountryCode}.errors.missingCompanyName`,
              'supplierValidation.supplierRegister.errors.missingCompanyName',
            ],
      },
      {
        max: FIELD_MAX_LENGTH.companyName,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.companyName,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
  additionalCompanyName: {
    initialValue: formInitialValues.supplierValues?.additionalCompanyName ?? undefined,
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: isIndividual
          ? 'supplierValidation.supplierRegister.errors.missingAdditionalPersonalName'
          : 'supplierValidation.supplierRegister.errors.missingAdditionalCompanyName',
      },
      {
        max: FIELD_MAX_LENGTH.companyName,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.companyName,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
  companyAddress: {
    initialValue: asyncProcessMaskedData?.payee.address ?? formInitialValues.supplierValues?.companyAddress ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedData?.payee.address),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: isIndividual
          ? 'supplierValidation.supplierRegister.errors.missingAddress'
          : [
              `supplierValidation.supplierRegister.${supplierCountryCode}.errors.missingCompanyAddress`,
              'supplierValidation.supplierRegister.errors.missingCompanyAddress',
            ],
      },
      {
        max: FIELD_MAX_LENGTH.companyAddress,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.companyAddress,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
  companyAddressCountry: {
    initialValue: formInitialValues.supplierValues?.companyAddressCountry ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedData?.payee.address),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingAddressCountry',
      },
      {
        max: FIELD_MAX_LENGTH.countryCode,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.countryCode,
        },
      },
    ],
  },
  companyAddressCity: {
    initialValue: formInitialValues.supplierValues?.companyAddressCity ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedData?.payee.address),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingAddressCity',
      },
      {
        max: FIELD_MAX_LENGTH.companyAddressCity,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.companyAddressCity,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
  companyAddressState: {
    initialValue: formInitialValues.supplierValues?.companyAddressState ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedData?.payee.address),
    rules: [
      {
        required: addressCountryCode === COUNTRY_CODES.UnitedStates,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingAddressState',
      },
    ],
  },
  companyAddressZipCode: {
    initialValue: formInitialValues.supplierValues?.companyAddressZipCode ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedData?.payee.address),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingAddressZipcode',
      },
      {
        max: FIELD_MAX_LENGTH.companyAddressZipCode,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.companyAddressZipCode,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
  fullName: {
    initialValue: asyncProcessMaskedData?.contactInfo.fullName ?? formInitialValues.supplierValues?.fullName ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedData?.contactInfo.fullName),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingContactName',
      },
      {
        max: FIELD_MAX_LENGTH.fullName,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.fullName,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
  leiType: {
    initialValue:
      (asyncProcessMaskedData?.payee.legalIdentifier?.type ? AllLegalIdTypeForCompany.Other : undefined) ??
      formInitialValues.supplierValues?.leiType ??
      getInitialLegalIdTypValuesOfCountryForCompany(supplierCountryCode) ??
      undefined,
    disableAllRules: isDefined(asyncProcessMaskedData?.payee.legalIdentifier),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingLegalIdType',
      },
      {
        max: FIELD_MAX_LENGTH.leiTypeCode,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.leiTypeCode,
        },
      },
    ],
  },
  leiTypeOtherValue: {
    initialValue:
      asyncProcessMaskedData?.payee.legalIdentifier?.type ?? formInitialValues.supplierValues?.leiTypeOtherValue ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedData?.payee.legalIdentifier),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingOtherLegalId',
      },
      {
        max: FIELD_MAX_LENGTH.leiTypeCode,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.leiTypeCode,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
  leiValue: {
    initialValue: asyncProcessMaskedData?.payee.legalIdentifier?.id ?? formInitialValues.supplierValues?.leiValue ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedData?.payee.legalIdentifier),
    rules: [
      ...getAdditionalLeiValueRules(supplierCountryCode),
      ...getAdditionalLeiValueRulesForCountryForLeiType(supplierCountryCode, leiType),
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingLegalId',
      },
      {
        max: FIELD_MAX_LENGTH.leiValue,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.leiValue,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
  phoneCountryCode: {
    initialValue: formInitialValues.supplierValues?.phoneCountryCode ?? COUNTRY_CODES.UnitedStates,
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCountryCode',
      },
      {
        max: FIELD_MAX_LENGTH.dialCode,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.dialCode,
        },
      },
      {
        validator: onChangeValidateOtherFields<SupplierRegistrationCompanyInfoFormFields>(form, 'phoneNumber'),
      },
    ],
  },
  phoneNumber: {
    initialValue: asyncProcessMaskedData?.contactInfo.phoneNumber ?? formInitialValues.supplierValues?.phoneNumber ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedData?.contactInfo.phoneNumber),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidPhoneNumber',
      },
      {
        max: FIELD_MAX_LENGTH.phoneNumber,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.phoneNumber,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.numericWithSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidPhoneNumber',
      },
      {
        validator: validatePhoneNumber<SupplierRegistrationCompanyInfoFormFields>(
          form,
          'phoneCountryCode',
          convertToTranslatedMessage('supplierValidation.supplierRegister.errors.invalidPhoneNumber'),
          true,
        ),
      },
    ],
  },
  email: {
    initialValue: asyncProcessMaskedData?.contactInfo.email ?? formInitialValues.supplierValues?.email ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedData?.contactInfo.email),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidEmail',
      },
      {
        max: FIELD_MAX_LENGTH.email,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.email,
        },
      },
      {
        type: 'email',
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidEmail',
      },
      {
        validator: emailAdditionalValidations(
          convertToTranslatedMessage('supplierValidation.supplierRegister.errors.invalidEmail'),
        ),
      },
    ],
  },
  country: {
    initialValue: asyncProcessMaskedData?.payee.countryCode ?? formInitialValues.supplierValues?.country ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedData?.payee.countryCode),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: isIndividual
          ? 'supplierValidation.supplierRegister.errors.missingAccountOwnerCountry'
          : 'supplierValidation.supplierRegister.errors.missingCompanyCountry',
      },
      {
        max: FIELD_MAX_LENGTH.countryCode,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.countryCode,
        },
      },
    ],
  },
  website: {
    initialValue: asyncProcessMaskedData?.payee.website ?? formInitialValues.supplierValues?.website ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedData?.payee.website),
    rules: [
      {
        max: FIELD_MAX_LENGTH.url,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.url,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.url,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidURL',
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
  referrer: {
    initialValue: formInitialValues.supplierValues?.referrer ?? undefined,
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingReferrer',
      },
      {
        max: FIELD_MAX_LENGTH.referrer,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.referrer,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
  registrationType: {
    initialValue:
      registrationTypeFromBoolean(asyncProcessMaskedData?.payee.isIndividual) ??
      formInitialValues.supplierValues?.registrationType?.toString() ??
      RegistrationType.company.toString(),
    disableAllRules: isDefined(asyncProcessMaskedData?.payee.isIndividual),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingRegistrationType',
      },
      {
        type: 'enum',
        enum: REGISTRATION_TYPES_VALUES,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
});

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-flow: row;
  align-items: start;
  grid-column-gap: 40px;
`;

const CountryDropdownItem = styled(LocaleAwareDropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

const FirstInputContainer = styled.div`
  flex: 3;
`;

const FixedInputContainer = styled.div`
  width: 115px;
  margin-right: 16px;
`;

const SecondInputContainer = styled.div`
  flex: 7;
`;

const LEITypeDropdownItem = styled(LocaleAwareDropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

const MultiInputsContainer = styled.div<{ isLTRFixed?: boolean; areChildrenRTL?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(p): string => (p.isLTRFixed ? `direction: ltr;` : '')}
  div {
    ${(p): string => (isDefined(p.areChildrenRTL) ? `direction: ${p.areChildrenRTL ? 'rtl' : 'ltr'};` : '')}
  }
`;

const InputBuffer = styled.div`
  width: 1px;
  height: 32px;
  color: var(--transparent-black-200);
`;

const PhoneCountryDropdownItem = styled(DropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);

  direction: ltr;
`;

const StyledCountryDialCode = styled.div`
  color: var(--transparent-black-900);
  direction: ltr;
`;

const PhoneCountryDisplay = styled(LocaleAwareDiv)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

const StyledFormRadioGroup = styled(FormRadioGroup)`
  margin-bottom: 22px;
  width: max-content;

  .MuiFormControlLabel-root {
    margin-inline-end: 16px !important;
    margin-inline-start: -11px !important;
  }
`;

const RadioOptionLabel = styled.div`
  color: var(--solid-black);
  font-size: 18px;
`;

const DateOfBirth = styled.div`
  position: relative;
  top: 4px;
`;

const TransparentSupplierRegistrationFormItemBox = styled(SupplierRegistrationFormItemBox)<{ shouldBeHidden: boolean }>`
  ${(p): string => (p.shouldBeHidden ? 'display: none;' : '')}
`;
