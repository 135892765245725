import React, { FC, ReactNode } from 'react';
import InnerCard from '@app/components/card/InnerCard';
import { SubtitleRegularStartTransparentBlack900 } from '@app/components/Text';
import { RowSeparator } from '@app/components/Styles';
import styled from '@emotion/styled';
import NationalityFlag from '@app/components/NationalityFlag';
import ReactPlaceholder from 'react-placeholder';
import BankIcon from '@app/images/bank.svg';
import SVG from '@app/components/SVG';
import withPlaceholder from '@app/components/withPlaceholder';
import Nationality from '@app/components/Nationality';
import {
  convertValidatedAccountValidationLevelToText,
  convertValidatedAccountValidationSupplierValidationLevelToText,
  ValidatedPayeeAccountValidationStatus,
  ValidatedPayeeAccountValidationStatusSupplierValidationVerification,
} from '@app/domain/validatedPayeeAccount';
import { convertTimestampToDefaultTimeString } from '@app/utils/timeUtils';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';

export interface ValidatedPayeeAccountDisplay {
  status: ValidatedPayeeAccountValidationStatus | ValidatedPayeeAccountValidationStatusSupplierValidationVerification | null;
  accountDetails: MorteeAccountDetailsExtended;
  bankName: string | null | undefined;
  furtherCredit: string | null | undefined;
  bankCountryCode: string | null | undefined;
  supplierValidationRegistrationNumber: string | null | undefined;
  validationTimestamp: number | null | undefined;
}

interface DataProps {
  validatedPayeeAccount: ValidatedPayeeAccountDisplay;
  dataTestId: string;
}

interface DisplayProps {
  className?: string;
}

const ValidatedPayeeAccountManagementCard: FC<DisplayProps & DataProps> = (props) => {
  const { validatedPayeeAccount, dataTestId, className } = props;

  const {
    accountDetails,
    bankCountryCode,
    status,
    bankName,
    furtherCredit,
    supplierValidationRegistrationNumber,
    validationTimestamp,
  } = validatedPayeeAccount;
  const { accountNumber, swiftCode, bankCode, countryCode, iban, branchCode, localFormat } = accountDetails;

  function calculateValidationTypeToDisplay(
    status: ValidatedPayeeAccountValidationStatus | ValidatedPayeeAccountValidationStatusSupplierValidationVerification | null,
  ): string {
    if (Object.values(ValidatedPayeeAccountValidationStatus).includes(status as ValidatedPayeeAccountValidationStatus)) {
      return convertValidatedAccountValidationLevelToText(status as ValidatedPayeeAccountValidationStatus);
    }
    return convertValidatedAccountValidationSupplierValidationLevelToText(
      status as ValidatedPayeeAccountValidationStatusSupplierValidationVerification,
    );
  }

  const fields: Record<string, ReactNode> = {
    'Validation Type': calculateValidationTypeToDisplay(status),
    'Validation Date': validationTimestamp ? convertTimestampToDefaultTimeString(validationTimestamp) : null,
    IBAN: iban,
    'SWIFT code': swiftCode,
    Country: countryCode ? <Nationality countryCode={countryCode} /> : undefined,
    'Bank code': bankCode,
    'Branch code': branchCode,
    'Account number': accountNumber,
    ...(localFormat && { CLABE: localFormat }),
  };

  return (
    <InnerCard className={className} data-testid={dataTestId}>
      <Title>
        <SVG accessibilityLabel='' image={BankIcon} width={30} height={25} />
        <BankName data-testid='account-bank-name'>{bankName}</BankName>
        <NationalityFlag countryCode={bankCountryCode} tooltip />
      </Title>
      <PaddedRowSeparator light />
      <DataGrid>
        {Object.entries(fields).map(([fieldName, fieldValue]) => (
          <DataGridRow key={fieldName} title={fieldName} valueDataTest={`account-details-${fieldName}`}>
            {fieldValue}
          </DataGridRow>
        ))}
        {furtherCredit && (
          <DataGridRow key={'Further Credit'} title={'Further Credit'} valueDataTest={'account-details-further-credit'}>
            {furtherCredit}
          </DataGridRow>
        )}
        {supplierValidationRegistrationNumber && (
          <DataGridRow
            key={'Supplier Validation Registration Number'}
            title={'Supplier Validation Registration Number'}
            valueDataTest={'account-details-supplier-validation-registration-number'}
          >
            {supplierValidationRegistrationNumber}
          </DataGridRow>
        )}
      </DataGrid>
    </InnerCard>
  );
};

const Placeholder: FC<DisplayProps> = ({ className }) => {
  return (
    <InnerCard className={className}>
      <Title>
        <ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 230, margin: '0.2em' }} />
      </Title>
      <PaddedRowSeparator light />
      <DataGrid>
        <DataGridRow
          key={1}
          title={<ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 66, margin: '0.2em' }} />}
          valueDataTest={''}
        >
          {<ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 160, margin: '0.2em' }} />}
        </DataGridRow>
        <DataGridRow
          key={2}
          title={<ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 60, margin: '0.2em' }} />}
          valueDataTest={''}
        >
          {<ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 190, margin: '0.2em' }} />}
        </DataGridRow>
        <DataGridRow
          key={3}
          title={<ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 75, margin: '0.2em' }} />}
          valueDataTest={''}
        >
          {<ReactPlaceholder type='textRow' ready={false} children='' style={{ width: 110, margin: '0.2em' }} />}
        </DataGridRow>
      </DataGrid>
    </InnerCard>
  );
};

export default withPlaceholder<DisplayProps, DataProps>(ValidatedPayeeAccountManagementCard, Placeholder);

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const BankName = styled(SubtitleRegularStartTransparentBlack900.div)`
  margin-left: 16px;
  flex: 1;
`;

const PaddedRowSeparator = styled(RowSeparator)`
  margin: 20px 0;
`;
