import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { ArchivedValidatedPayee } from '@mortee/domain/mvfCheck';
import { getCountryName } from '@app/domain/countries';
import CategoryAndValues from '@app/components/CategoryAndValues';
import { formatAccountWithSwiftban } from '@app/domain/accountDetailsFormatters';
import { groupBy } from '@app/utils/utils';
import { calculateValidatedSwiftCodeToDisplay, doesAccountDetailsContainOther } from '@app/domain/accountsDetailsHelpers';
import { distinctValues } from '@app/utils/arrayUtils';
import { getLEIText } from '@app/utils/legalIdentifierUtils';
import {
  translateValidatedAccountValidationLevel,
  ValidatedPayeeAccountValidationStatus,
} from '@app/domain/validatedPayeeAccount';

interface ArchivedValidatedPayeeValidationsProps {
  archivedValidatedPayee: ArchivedValidatedPayee;
  inputAccountDetails?: MorteeAccountDetails;
  className?: string;
}

enum ValidatedAccountActualValidationLevel {
  extendedValidation,
  standardValidation,
}

const validatedAccountActualValidationLevelOrder: ValidatedAccountActualValidationLevel[] = Object.values(
  ValidatedAccountActualValidationLevel,
) as ValidatedAccountActualValidationLevel[];

const convertValidatedAccountValidationLevelToActualValidationLevel = (
  validationStatus: ValidatedPayeeAccountValidationStatus,
): ValidatedAccountActualValidationLevel | null => {
  switch (validationStatus) {
    case ValidatedPayeeAccountValidationStatus.extendedValidation: {
      return ValidatedAccountActualValidationLevel.extendedValidation;
    }
    case ValidatedPayeeAccountValidationStatus.lightweightValidation:
    case ValidatedPayeeAccountValidationStatus.reverseWireValidation:
    case ValidatedPayeeAccountValidationStatus.externalBankValidation:
    case ValidatedPayeeAccountValidationStatus.lyonsBankValidation:
    case ValidatedPayeeAccountValidationStatus.internalValidation: {
      return ValidatedAccountActualValidationLevel.standardValidation;
    }
  }

  return null;
};

const ArchivedValidatedPayeeValidations: FunctionComponent<ArchivedValidatedPayeeValidationsProps> = (props) => {
  const { archivedValidatedPayee, className, inputAccountDetails } = props;
  const { validatedCountryCode, validatedLegalEntityIdentifiers, validatedPayeeAccounts } = archivedValidatedPayee;
  const alreadySeenAccountDetailsRepresentation: MorteeAccountDetails[] = [];

  const accountsGroupedByActualValidationLevel = groupBy(validatedPayeeAccounts, (account) =>
    convertValidatedAccountValidationLevelToActualValidationLevel(account.validationStatus),
  );

  // Order accounts by validation level - the best first
  // and remove all duplicate representations in other accounts that have lesser validation level
  const formattedValidatedPayeeAccounts = validatedAccountActualValidationLevelOrder.flatMap((validationLevel) => {
    const accountsWithValidationLevel = accountsGroupedByActualValidationLevel.get(validationLevel);
    if (!accountsWithValidationLevel) {
      return [];
    }

    // remove all accounts are contained in accounts that already exist in higher validation level
    const validatedAccountsWithValidationLevel = accountsWithValidationLevel.filter((validatedPayeeAccount) => {
      const doesThisDetailsAlreadySeen = alreadySeenAccountDetailsRepresentation.some((alreadySeenAccountDetails) =>
        doesAccountDetailsContainOther(alreadySeenAccountDetails, validatedPayeeAccount.accountDetails),
      );

      return !doesThisDetailsAlreadySeen;
    });

    // Mark all of the accounts in this level as "already seen"
    // so that accounts in lower validation level will not list those formatted accounts
    validatedAccountsWithValidationLevel.forEach((validatedAccount) =>
      alreadySeenAccountDetailsRepresentation.push(validatedAccount.accountDetails),
    );

    return validatedAccountsWithValidationLevel;
  });

  return (
    <Container className={className} data-testid='mvf-changelog-table-dv-validated-payee-info'>
      <CategoryAndValues label="Vendor ID's" inlineLabel>
        {distinctValues(validatedLegalEntityIdentifiers?.map(getLEIText))?.map((leiText) => (
          <div key={leiText}>{leiText}</div>
        ))}
      </CategoryAndValues>
      <CategoryAndValues label='Country' inlineLabel>
        {getCountryName(validatedCountryCode)}
      </CategoryAndValues>
      {formattedValidatedPayeeAccounts.map((validatedPayeeAccount, index) => (
        <CategoryAndValues key={index} label={translateValidatedAccountValidationLevel(validatedPayeeAccount.validationStatus)}>
          {formatAccountWithSwiftban({
            ...validatedPayeeAccount.accountDetails,
            swiftCode: calculateValidatedSwiftCodeToDisplay(
              validatedPayeeAccount.accountDetails.swiftCode,
              // Only compare (and add) if there is value in the validation response
              validatedPayeeAccount.accountDetails.swiftCode ? inputAccountDetails?.swiftCode : null,
            ),
          }).map((formattedAccount) => (
            <div key={formattedAccount}>{formattedAccount}</div>
          ))}
        </CategoryAndValues>
      ))}
    </Container>
  );
};

export default ArchivedValidatedPayeeValidations;

const Container = styled.div`
  margin-bottom: 18px;

  & > * {
    margin-bottom: 13px;
  }
`;
