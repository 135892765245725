import {
  LegalEntityIdTypes,
  NoticesBulletsData,
  StoreValidatedPayeeAccountRequest,
  StoreValidatedPayeeRequest,
  transformValidatedPayeeAccountStoreWarning,
  transformValidatedPayeeStoreWarning,
  ValidatedPayeeAccountStoreWarning,
  ValidatedPayeeAccountStoreWarningType,
  ValidatedPayeeStoreWarning,
  ValidatedPayeeStoreWarningType,
} from '@mortee/domain/vaildatedPayeeManagement';
import { isTruthy } from '@app/utils/utils';
import validatedPayeeManagementServices, { transformLegalEntityIdTypes } from '@mortee/services/validatedPayeeManagementServices';
import MorteeNavigationStore from '@mortee/stores/infraStores/MorteeNavigationStore';
import React, { ReactNode } from 'react';
import { getCountryName } from '@app/domain/countries';
import { AggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';
import { computed, makeObservable, observable } from 'mobx';
import Loadable, { lazyLoadable, LoadableCreator } from '@app/utils/Loadable';
import { getFormattedLEI } from '@app/domain/legalEntityIdentifier';
import validationSystemServices from '@mortee/services/validationSystemServices';
import {
  SupplierValidationAccountVerificationRecord,
  SupplierValidationPayeeWithAccountsVerificationWarnings,
  transformSupplierValidationPayeeWithAccountsVerificationWarnings,
} from '@mortee/domain/validationSystem';
import { PayeeAndAccountsWithWarnings } from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/reviewModals/VerificationPayeeAndAccountsChanges';

export enum KnoxIdOrigin {
  generate = 'generate',
  existing = 'existing',
}

export default class ValidatedPayeesManagementStore {
  @observable private _allLeiTypes: Loadable<LegalEntityIdTypes> = LoadableCreator.notStarted();
  private _navigationStore: MorteeNavigationStore;

  constructor(navigationStore: MorteeNavigationStore) {
    makeObservable(this);
    this._navigationStore = navigationStore;
  }

  @computed
  get allLeiTypes(): Loadable<LegalEntityIdTypes> {
    return lazyLoadable(
      () => this._allLeiTypes,
      (newValue) => (this._allLeiTypes = newValue),
      async (): Promise<LegalEntityIdTypes> => {
        const allLEITypesServerResponse = await validatedPayeeManagementServices.getAllLegalEntityIdTypes();
        return transformLegalEntityIdTypes(allLEITypesServerResponse);
      },
    );
  }

  private getCountryStringForMessage = (countryCode: string | undefined | null): string => {
    if (!countryCode) {
      return 'Unknown';
    }

    return `${countryCode} - ${getCountryName(countryCode)}`;
  };

  getAdditionalPayeeWebWarnings(
    verificationRecordData: SupplierValidationPayeeWithAccountsVerificationWarnings | null | undefined,
  ): ValidatedPayeeStoreWarning[] {
    const additionWarnings: ValidatedPayeeStoreWarning[] = [];
    const ssnLei = this.allLeiTypes?.result?.regularTypes?.map((lei) => lei).find((lei) => lei.name === 'SSN');

    if (!verificationRecordData?.payee?.payeeVerification) {
      return [];
    }

    const data = verificationRecordData.payee.payeeVerification;
    if (data.legalEntityIdentifiers?.find((lei) => lei?.typeId === ssnLei?.id) && !data.isPrivate) {
      additionWarnings.push({ type: ValidatedPayeeStoreWarningType.ssnLeiTypeOnPublicPayee });
    }

    if (!data.alreadyExistingValidatedPayee && !data.legalEntityIdentifiers?.length) {
      additionWarnings.push({ type: ValidatedPayeeStoreWarningType.noLeiOnNewPayee });
    }

    return additionWarnings;
  }

  getAdditionalAccountWebWarnings(
    data: SupplierValidationAccountVerificationRecord | null | undefined,
  ): ValidatedPayeeAccountStoreWarning[] {
    if (!data) {
      return [];
    }

    if (data.iban || (data.swift && data.accountNumber)) {
      return [];
    }

    if (data.accountNumber && data.countryCode && (data.bankCode || data.branchCode)) {
      return [];
    }

    return [{ type: ValidatedPayeeAccountStoreWarningType.noAccountFullRepresentation }];
  }

  calcPayeeAndAccountsCreationAlerts = async (
    validationRecordStaticId: string,
    isVerificationWarnings?: boolean,
  ): Promise<PayeeAndAccountsWithWarnings> => {
    const warnings = await validationSystemServices.getVerificationDataValidationRecordWarningsByStaticId(
      validationRecordStaticId,
    );
    const payeeWithAccountsWarnings = transformSupplierValidationPayeeWithAccountsVerificationWarnings(warnings);

    const renderedPayeeWarnings = payeeWithAccountsWarnings.payee.warnings
      .concat(this.getAdditionalPayeeWebWarnings(payeeWithAccountsWarnings))
      .map((warning) => this.renderPayeeWarningAlert(warning))
      .filter(isTruthy);

    const renderedPayeeAccountsWarnings: PayeeAndAccountsWithWarnings['accounts'] = payeeWithAccountsWarnings.accounts.map(
      (account) => {
        const result = account.warnings
          .concat(this.getAdditionalAccountWebWarnings(account.accountVerification))
          .map((warning) => this.renderPayeeAccountWarningAlert(warning, undefined, isVerificationWarnings))
          .filter(isTruthy);
        return {
          renderedWarnings: result,
          record: account.accountVerification,
        };
      },
    );

    return {
      payee: {
        record: payeeWithAccountsWarnings.payee.payeeVerification,
        renderedWarnings: renderedPayeeWarnings,
      },
      accounts: renderedPayeeAccountsWarnings,
    };
  };

  private renderPayeeWarningAlert = (warning: ValidatedPayeeStoreWarning, existingPayeeUniformId?: string): ReactNode | null => {
    switch (warning.type) {
      case ValidatedPayeeStoreWarningType.missingAddress:
        return 'Missing Primary Address';
      case ValidatedPayeeStoreWarningType.missingSanctionScreening:
        return 'The nsKnox organization related to this payee is expecting sanction screening information';
      case ValidatedPayeeStoreWarningType.ssnLeiTypeOnPublicPayee:
        return 'Can’t add SSN legal Id to a non-private account';
      case ValidatedPayeeStoreWarningType.noLeiOnNewPayee:
        return 'No legal IDs on a new payee';
      case ValidatedPayeeStoreWarningType.duplicateLegalIdValueWithinThisPayee:
        return `Legal identifiers have the same value: ${warning.leisWithTheSameValue
          .map((lei) => getFormattedLEI(lei, true))
          .join(', ')}`;
      case ValidatedPayeeStoreWarningType.payeeCountryNotMatchLegalIdsCountry:
        return warning.leisWithDifferentCountryThenPayee.map((lei) => (
          <span>
            {lei.typeName || 'Legal identifier'} does not have the same country as the payee: {getFormattedLEI(lei, true)}
            <br />
          </span>
        ));
      case ValidatedPayeeStoreWarningType.existingLegalIdInOtherPayees:
        if (warning.otherValidatedPayees.filter((payee) => payee.uniformId !== existingPayeeUniformId).length) {
          return warning.otherValidatedPayees.map((validatedPayee) => (
            <div key={validatedPayee.uniformId}>
              Some of the legal ids you have entered exist in the payee:{' '}
              <a
                target='_blank'
                rel='noreferrer'
                href={this._navigationStore.generateManageValidatedPayeePageHref(validatedPayee.uniformId)}
              >
                {validatedPayee.mainName}
              </a>
            </div>
          ));
        }
    }
  };

  calcPayeeCreationAlerts = async (
    storePayeeRequest: StoreValidatedPayeeRequest,
    existingPayeeUniformId: string | undefined,
  ): Promise<ReactNode[]> => {
    const warnings = await validatedPayeeManagementServices.calculateValidatedPayeeStoreWarnings(storePayeeRequest);

    return warnings
      .map(transformValidatedPayeeStoreWarning)
      .map((warning) => this.renderPayeeWarningAlert(warning, existingPayeeUniformId))
      .filter(isTruthy);
  };

  private renderPayeeAccountWarningAlert = (
    warning: ValidatedPayeeAccountStoreWarning,
    storeAccountRequest?: StoreValidatedPayeeAccountRequest,
    isVerificationWarnings?: boolean,
  ): NonNullable<ReactNode> => {
    switch (warning.type) {
      case ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchPayeeCountry: {
        return `Bank country (${this.getCountryStringForMessage(warning.bankCountryCode)}) \
does not match payee country (${this.getCountryStringForMessage(warning.payeeCountryCode)})`;
      }
      case ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchAccountDetailsCountry: {
        return `Account country (${this.getCountryStringForMessage(warning.accountDetailsCountryCode)}) \
does not match bank country (${this.getCountryStringForMessage(warning.bankCountryCode)})`;
      }
      case ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchAccountDetailsIBANCountry: {
        return `IBAN's country (${this.getCountryStringForMessage(warning.ibanCountryCode)}) \
does not match bank country (${this.getCountryStringForMessage(warning.bankCountryCode)})`;
      }
      case ValidatedPayeeAccountStoreWarningType.bankCountryNotMatchAccountDetailsSWIFTCountry: {
        return `SWIFT's country (${this.getCountryStringForMessage(warning.swiftCountryCode)}) \
does not match bank country (${this.getCountryStringForMessage(warning.bankCountryCode)})`;
      }
      case ValidatedPayeeAccountStoreWarningType.existingRepresentationsInOtherValidatedAccounts: {
        return warning.validatedPayees.map((validatedPayee) => (
          <div>
            Some of the account details you have entered exist in an account of the payee:{' '}
            <a
              target='_blank'
              rel='noreferrer'
              href={this._navigationStore.generateManageValidatedPayeePageHref(validatedPayee.uniformId)}
            >
              {validatedPayee.mainName}
            </a>
          </div>
        ));
      }
      case ValidatedPayeeAccountStoreWarningType.supplierValidationProcessNotFound:
        if (storeAccountRequest?.supplierValidationRegistrationNumber) {
          return `No supplier validation process found for registration number -  ${storeAccountRequest?.supplierValidationRegistrationNumber}.`;
        }
        if (isVerificationWarnings) {
          return 'No registration number (Registration-forms) linked to this record.';
        }
        return 'No supplier validation registration number was entered.';
      case ValidatedPayeeAccountStoreWarningType.svManagementRecordNotFound:
        return `No supplier validation management record found for registration number - ${storeAccountRequest?.supplierValidationRegistrationNumber}.`;
      case ValidatedPayeeAccountStoreWarningType.bankCountryMissing:
        return `Account country is missing`;
      case ValidatedPayeeAccountStoreWarningType.noAccountFullRepresentation:
        return "Account doesn't have full representation";
    }
  };

  calcPayeeAccountCreationAlerts = async (
    storeAccountRequest: StoreValidatedPayeeAccountRequest,
    owningValidatedPayee: AggregatedValidatedPayee,
  ): Promise<ReactNode[]> => {
    const warnings = await validatedPayeeManagementServices.calculateValidatedPayeeAccountStoreWarnings(
      owningValidatedPayee.uniformId,
      storeAccountRequest,
    );

    return warnings
      .map(transformValidatedPayeeAccountStoreWarning)
      .map((warning) => this.renderPayeeAccountWarningAlert(warning, storeAccountRequest))
      .filter(isTruthy);
  };

  calcPayeeAccountCreationNotices = async (
    noticesBulletsData: NoticesBulletsData,
    storeAsOrganizationalAccount: boolean,
  ): Promise<ReactNode[]> => {
    if (noticesBulletsData.organizationName) {
      if (storeAsOrganizationalAccount) {
        return [
          `This action will make both the account and the payee entirely private for organization: ${noticesBulletsData.organizationName}`,
        ];
      }
      return [
        `You are about to create account with supplier validation registration number associated with organization: ${noticesBulletsData.organizationName}`,
      ];
    }
    return [];
  };
}
